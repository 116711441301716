(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('CurrencyUtils', CurrencyUtils);

    CurrencyUtils.$inject = [];

    function CurrencyUtils() {

        return {
            getCurrency: getCurrency
        };

        function getCurrency(currencyCode) {
            switch (currencyCode) {
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            case 'AOA':
                return 'Kz';
            case 'MZN':
                return 'MT';
            case 'CHF':
                return 'CHF';
            case null:
                return '€';
            default:
                return currencyCode;
            }
        }

    }
})();

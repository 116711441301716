(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('landing-page', {
            url: '/',
            data: {
                authorities: []
            },
            params: {
                section: null
            },
            views: {
                'main@': {
                    templateUrl: 'app/layouts/landing-page/landing-page.html',
                    controller: 'LandingPageController',
                    controllerAs: 'vm'
                },
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('login');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('landingPage');
                        $translatePartialLoader.addPart('faqs');
                        return $translate.refresh();
                    }
                ]
            }
        });
    }
})();

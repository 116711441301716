(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('AmaSafeCredentialDialogController', AmaSafeCredentialDialogController);

    AmaSafeCredentialDialogController.$inject = [
        '$state',
        '$timeout',
        '$scope',
        '$translate',
        '$uibModal',
        'entity',
        'AmaSafeCredential',
        'Notification',
        'DocumentationUtils'
    ];

    function AmaSafeCredentialDialogController($state, $timeout, $scope, $translate, $uibModal, entity, AmaSafeCredential, Notification, DocumentationUtils) {
        var vm = this;
        vm.communicationEntity = entity;
        vm.clear = clear;
        vm.save = save;
        vm.setAccessTokenFile = setAccessTokenFile;
        vm.setRefreshTokenFile = setRefreshTokenFile;
        vm.openReasonDetail = openReasonDetail;
        vm.downloadAMASAFEUserManual = downloadAMASAFEUserManual;

        vm.accessToken = undefined;
        vm.refreshToken = undefined;

        vm.reasons = [
            'PROOF_OF_ORIGIN',
            'PROOF_OF_APPROVAL',
            'PROOF_OF_CREATION',
            'DATA_AUTHENTICATION',
            'ENTITY_AUTHENTICATION',
            'AUTHORSHIP',
            'REVIEW',
            'COPY',
            'SIGNATURE_WITNESS',
            'BOUND_TO_DATA_SIGNED',
            'INTERMEDIATE_APPROVAL'
        ];

        activate();

        function activate() {
            if (!entity || !entity.id) {
                $state.go('communication-entity');
                Notification.error({
                    message: $translate.instant('eDocumentsApp.amaSafeCredential.message.error.communicationEntityUndefined')
                });
            } else {
                vm.amaSafeCredential = {};
                vm.amaSafeCredential.communicationEntityId = entity.id;
                $uibModal
                    .open({
                        templateUrl: 'app/entities/ama-safe-credential/ama-safe-credential-disclaimer-dialog.html',
                        controller: 'AmaSafeCredentialDisclaimerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg'
                    })
                    .result.then(
                    function() {
                        // do nothing
                    },
                    function() {
                        $state.go('communication-entity-detail.edit', { id: vm.communicationEntity.id });
                    }
                );
            }
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('communication-entity-detail.edit', { id: vm.communicationEntity.id });
        }

        function downloadAMASAFEUserManual() {
            DocumentationUtils.downloadAMASAFEUserManual();
        }

        function save() {
            vm.isSaving = true;
            if (angular.isDefined(vm.amaSafeCredential.id) && vm.amaSafeCredential.id !== null) {
                Notification.error({ message: $translate.instant('eDocumentsApp.amaSafeCredential.message.error.idDefined') });
            } else {
                var postObject = {
                    amaSafeCredential: vm.amaSafeCredential,
                    accessToken: vm.accessToken,
                    refreshToken: vm.refreshToken
                };
                AmaSafeCredential.create(postObject, onSaveSuccess, onSaveError);
            }
        }

        function setAccessTokenFile($file, invalid) {
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function(e) {
                    vm.accessToken = e.target.result.substring(e.target.result.indexOf('base64,') + 'base64,'.length);
                    $scope.$apply();
                };
            }
            if (invalid.length > 0) {
                Notification.error({
                    title: $translate.instant('error.invalidFile'),
                    message: $translate.instant('error.onlyTextFilesAllowed')
                });
            }
        }

        function setRefreshTokenFile($file, invalid) {
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function(e) {
                    vm.refreshToken = e.target.result.substring(e.target.result.indexOf('base64,') + 'base64,'.length);
                    $scope.$apply();
                };
            }
            if (invalid.length > 0) {
                Notification.error({
                    title: $translate.instant('error.invalidFile'),
                    message: $translate.instant('error.onlyTextFilesAllowed')
                });
            }
        }

        function openReasonDetail() {
            $uibModal.open({
                templateUrl: 'app/entities/ama-safe-credential/ama-safe-credential-reason-dialog.html',
                controller: 'AmaSafeCredentialReasonDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:amaSafeCredentialUpdate', result);
            $state.go('communication-entity-detail.edit', { id: vm.communicationEntity.id });
            vm.isSaving = false;
        }

        function onSaveError(error) {
            vm.isSaving = false;
            if (error.data.detail) {
                Notification.error({ message: $translate.instant(error.data.detail) });
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('invoice-line', {
                parent : 'entity',
                url    : '/invoice-line',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.invoiceLine.home.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/invoice-line/invoice-lines.html',
                        controller  : 'InvoiceLineController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('invoiceLine');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invoice-line-detail', {
                parent : 'invoice-line',
                url    : '/invoice-line/{id}',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.invoiceLine.detail.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/invoice-line/invoice-line-detail.html',
                        controller  : 'InvoiceLineDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('invoiceLine');
                        return $translate.refresh();
                    }],
                    entity                : ['$stateParams', 'InvoiceLine', function ($stateParams, InvoiceLine) {
                        return InvoiceLine.get({id: $stateParams.id}).$promise;
                    }],
                    previousState         : ["$state", function ($state) {
                        return {
                            name  : $state.current.name || 'invoice-line',
                            params: $state.params,
                            url   : $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('invoice-line-detail.edit', {
                parent : 'invoice-line-detail',
                url    : '/detail/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/invoice-line/invoice-line-dialog.html',
                        controller  : 'InvoiceLineDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['InvoiceLine', function (InvoiceLine) {
                                return InvoiceLine.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('invoice-line.new', {
                parent : 'invoice-line',
                url    : '/new',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$state', '$uibModal', function ($state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/invoice-line/invoice-line-dialog.html',
                        controller  : 'InvoiceLineDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: function () {
                                return {
                                    lineNumber            : null,
                                    itemCode              : null,
                                    itemDescription       : null,
                                    supplierLotCode       : null,
                                    lotValidUntil         : null,
                                    lotQualityCode        : null,
                                    productType           : null,
                                    quantity              : null,
                                    bonusQuantity         : null,
                                    unitOfMeasure         : null,
                                    grossUnitPrice        : null,
                                    netUnitPrice          : null,
                                    grossLineAmount       : null,
                                    lineDiscountAmount    : null,
                                    lineNetAmount         : null,
                                    vatRate               : null,
                                    vatAmount             : null,
                                    deliveryDate          : null,
                                    lineDiscountPercentage: null,
                                    id                    : null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('invoice-line', null, {reload: 'invoice-line'});
                    }, function () {
                        $state.go('invoice-line');
                    });
                }]
            })
            .state('invoice-line.edit', {
                parent : 'invoice-line',
                url    : '/{id}/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/invoice-line/invoice-line-dialog.html',
                        controller  : 'InvoiceLineDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['InvoiceLine', function (InvoiceLine) {
                                return InvoiceLine.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('invoice-line', null, {reload: 'invoice-line'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('invoice-line.delete', {
                parent : 'invoice-line',
                url    : '/{id}/delete',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/invoice-line/invoice-line-delete-dialog.html',
                        controller  : 'InvoiceLineDeleteController',
                        controllerAs: 'vm',
                        size        : 'md',
                        resolve     : {
                            entity: ['InvoiceLine', function (InvoiceLine) {
                                return InvoiceLine.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('invoice-line', null, {reload: 'invoice-line'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();

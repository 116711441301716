(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PARTNER', 'ROLE_SUPERADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('documentType');
                        $translatePartialLoader.addPart('documentStatus');
                        return $translate.refresh();
                    }
                ]
            }
        });
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('SelectedDocumentsController', SelectedDocumentsController);

    SelectedDocumentsController.$inject = [
        '$state',
        '$uibModalInstance',
        '$ngConfirm',
        '$scope',
        'list',
        'selectChange',
        'exportDocumentsFiles',
        'exportSelectedDocsToCSV',
        'exportSelectedDocsToExcel',
        'Document',
        'Notification',
        'CurrencyUtils',
        '$uibModal',
        '$translate',
        '$log'
    ];

    function SelectedDocumentsController(
        $state,
        $uibModalInstance,
        $ngConfirm,
        $scope,
        list,
        selectChange,
        exportDocumentsFiles,
        exportSelectedDocsToCSV,
        exportSelectedDocsToExcel,
        Document,
        Notification,
        CurrencyUtils,
        $uibModal,
        $translate,
        $log
    ) {
        var vm = this;

        vm.documents = list;
        vm.clear = clear;
        vm.selectChangeModal = selectChangeModal;
        vm.exportSelectedDocsToExcelModal = exportSelectedDocsToExcelModal;
        vm.exportSelectedDocsToCSVModal = exportSelectedDocsToCSVModal;
        vm.exportDocumentsFilesModal = exportDocumentsFilesModal;
        $scope.selectedAll = true;
        vm.delete = deleteDocuments;
        vm.resend = resend;
        vm.isAbleToResend = isAbleToResend;
        vm.executeWaitingAction = executeWaitingAction;
        vm.isAbleToExecuteWaitingAction = isAbleToExecuteWaitingAction;
        vm.getCurrency = CurrencyUtils.getCurrency;
        vm.deselectAll = deselectAll;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function selectChangeModal(document) {
            selectChange(document);
        }

        function exportSelectedDocsToExcelModal() {
            exportSelectedDocsToExcel();
        }

        function exportSelectedDocsToCSVModal() {
            exportSelectedDocsToCSV();
        }

        function exportDocumentsFilesModal() {
            exportDocumentsFiles();
        }

        function deselectAll() {
            $ngConfirm({
                title: 'Confirmar!',
                content: 'Esta ação irá desseleccionar todas as opções.<br>Tem a certeza que deseja continuar?',
                scope: $scope,
                buttons: {
                    confirm: {
                        text: 'Continuar',
                        btnClass: 'btn-warning',
                        action: function() {
                            clear();
                            $state.reload();
                        }
                    },
                    close: {
                        text: 'Cancelar',
                        btnClass: 'btn-primary',
                        action: function() {
                            $scope.selectedAll = true;
                            $scope.$apply();
                        }
                    }
                }
            });
        }

        function deleteDocuments() {
            $uibModal
                .open({
                    templateUrl: 'app/entities/document/document-delete-selected-dialog.html',
                    controller: 'DocumentDeleteSelectedController',
                    controllerAs: 'vm',
                    size: 'md'
                })
                .result.then(
                function() {
                    var keys = Object.keys(vm.documents);
                    var documentsIdsArray = [];
                    for (var i = 0; i < keys.length; i++) {
                        documentsIdsArray.push({ id: vm.documents[keys[i]].id });
                    }
                    Document.removeDocuments(
                        { documentsIds: JSON.stringify({ id: documentsIdsArray }) },
                        function() {
                            $state.reload();
                            clear();
                        },
                        function(e) {
                            $log.error(e);
                            Notification.error({ message: 'Ocorreu um erro ao remover os documentos selecionados' });
                        }
                    );
                },
                function() {
                }
            );
        }

        function resend() {
            var documentsIdsArray = [];
            angular.forEach(vm.documents, function(item) {
                if (item.status === 'ERROR') {
                    documentsIdsArray.push({ id: item.id });
                }
            });
            Document.redoStepForDocumentsList(
                { documentsIds: JSON.stringify({ id: documentsIdsArray }) },
                function() {
                    $state.reload();
                    $uibModalInstance.close();
                },
                function(e) {
                    $log.error(e);
                    Notification.error({ message: $translate.instant('eDocumentsApp.document.message.errorResendingSelectedDocuments') });
                }
            );
        }

        function isAbleToResend() {
            var isAbleToResend = true;
            angular.forEach(vm.documents, function(item) {
                if (item.status !== 'ERROR') {
                    isAbleToResend = false;
                }
            });
            return isAbleToResend;
        }

        function executeWaitingAction() {
            var documentsIdsArray = [];
            angular.forEach(vm.documents, function(item) {
                if (item.status === 'WAITING') {
                    documentsIdsArray.push({ id: item.id });
                }
            });
            Document.doStepForDocumentsList(
                { documentsIds: JSON.stringify({ id: documentsIdsArray }) },
                function() {
                    $state.reload();
                    $uibModalInstance.close();
                },
                function(e) {
                    $log.error(e);
                    Notification.error({ message: $translate.instant('eDocumentsApp.document.message.errorExecutingSelectedDocuments') });
                }
            );
        }

        function isAbleToExecuteWaitingAction() {
            var isAbleToExecuteWaitingAction = true;
            angular.forEach(vm.documents, function(item) {
                if (item.status !== 'WAITING') {
                    isAbleToExecuteWaitingAction = false;
                }
            });
            return isAbleToExecuteWaitingAction;
        }
    }
})();

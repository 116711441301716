(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('AuditsController', AuditsController);

    AuditsController.$inject = ['$state', 'AuditsService', 'ParseLinks', 'PaginationUtil'];

    function AuditsController($state, AuditsService, ParseLinks, PaginationUtil) {
        var vm = this;

        vm.audits = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.page = 1;
        vm.totalItems = null;
        vm.loadAll = loadAll;
        vm.isSearchActive = isSearchActive;
        vm.reset = reset;

        function loadAll() {
            vm.filters = PaginationUtil.updateFilters($state.current.name, vm.page - 1, vm.filters.filterBy, vm.predicate, vm.reverse, vm.itemsPerPage);

            AuditsService.query(
                {
                    page: vm.filters.currentPage,
                    size: vm.filters.itemsPerPage,
                    search: search(),
                    sort: sort()
                },
                function(result, headers) {
                    vm.audits = result;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                }
            );

            function sort() {
                if (!vm.predicate) {
                    vm.predicate = 'auditEventDate';
                }
                return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            }
        }

        function search() {
            var result = '';
            for (var x in vm.filters.filterBy) {
                if (vm.filters.filterBy[x] && vm.filters.filterBy[x].fromDate) {
                    result +=
                        vm.filters.filterBy[x].name +
                        '>' +
                        moment(vm.filters.filterBy[x].fromDate)
                            .tz('Europe/Lisbon')
                            .set({
                                hours: 0,
                                minutes: 0,
                                seconds: 0
                            })
                            .toISOString(true) +
                        '#AND,';
                }
                if (vm.filters.filterBy[x] && vm.filters.filterBy[x].to_date) {
                    result +=
                        vm.filters.filterBy[x].name +
                        '<' +
                        moment(vm.filters.filterBy[x].to_date)
                            .tz('Europe/Lisbon')
                            .set({
                                hours: 0,
                                minutes: 0,
                                seconds: 0
                            })
                            .toISOString(true) +
                        '#AND,';
                }
                if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
                    result += vm.filters.filterBy[x].name + ':' + vm.filters.filterBy[x].value + '#AND,';
                }
            }
            return result;
        }

        function isSearchActive() {
            return (
                vm.filters.filterBy.user.value || vm.filters.filterBy.auditDate.fromDate || vm.filters.filterBy.auditDate.to_date || vm.filters.filterBy.status.value
            );
        }

        function reset() {
            vm.filters = PaginationUtil.clearFilters($state.current.name, filterBy());
            loadAll();
        }

        function filterBy() {
            return {
                user: {
                    name: 'principal',
                    value: undefined
                },
                auditDate: {
                    name: 'auditEventDate',
                    fromDate: undefined,
                    to_date: undefined
                },
                status: {
                    name: 'auditEventType',
                    value: undefined
                }
            };
        }

        function initFilters() {
            vm.filters = PaginationUtil.getFilters($state.current.name, filterBy());

            vm.page = vm.filters.currentPage + 1;
            vm.predicate = vm.filters.orderBy;
            vm.reverse = vm.filters.orderReverse;
            loadAll();
        }

        vm.$onInit = function() {
            initFilters();
        };

        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('communication-action-definition-error-translation', {
                parent : 'entity',
                url    : '/communication-action-definition-error-translation',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.communicationActionDefinitionErrorTranslation.home.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/communication-action-definition-error-translation/communication-action-definition-error-translations.html',
                        controller  : 'CommunicationActionDefinitionErrorTranslationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('communicationActionDefinitionErrorTranslation');
                        $translatePartialLoader.addPart('communicationErrorType');
                        $translatePartialLoader.addPart('documentStatus');
                        $translatePartialLoader.addPart('communicationStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('communication-action-definition-error-translation-detail', {
                parent : 'communication-action-definition-error-translation',
                url    : '/communication-action-definition-error-translation/{id}',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.communicationActionDefinitionErrorTranslation.detail.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/communication-action-definition-error-translation/communication-action-definition-error-translation-detail.html',
                        controller  : 'CommunicationActionDefinitionErrorTranslationDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('communicationActionDefinitionErrorTranslation');
                        $translatePartialLoader.addPart('communicationErrorType');
                        $translatePartialLoader.addPart('documentStatus');
                        $translatePartialLoader.addPart('communicationStatus');
                        return $translate.refresh();
                    }],
                    entity                : ['$stateParams', 'CommunicationActionDefinitionErrorTranslation', function ($stateParams, CommunicationActionDefinitionErrorTranslation) {
                        return CommunicationActionDefinitionErrorTranslation.get({id: $stateParams.id}).$promise;
                    }],
                    previousState         : ["$state", function ($state) {
                        return {
                            name  : $state.current.name || 'communication-action-definition-error-translation',
                            params: $state.params,
                            url   : $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('communication-action-definition-error-translation-detail.edit', {
                parent : 'communication-action-definition-error-translation-detail',
                url    : '/detail/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication-action-definition-error-translation/communication-action-definition-error-translation-dialog.html',
                        controller  : 'CommunicationActionDefinitionErrorTranslationDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['CommunicationActionDefinitionErrorTranslation', function (CommunicationActionDefinitionErrorTranslation) {
                                return CommunicationActionDefinitionErrorTranslation.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('communication-action-definition-error-translation.new', {
                parent : 'communication-action-definition-error-translation',
                url    : '/new',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$state', '$uibModal', function ($state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication-action-definition-error-translation/communication-action-definition-error-translation-dialog.html',
                        controller  : 'CommunicationActionDefinitionErrorTranslationDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: function () {
                                return {
                                    communicationErrorType   : null,
                                    value                    : null,
                                    targetDocumentStatus     : null,
                                    targetCommunicationStatus: null,
                                    retryActions             : null,
                                    id                       : null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('communication-action-definition-error-translation', null, {reload: 'communication-action-definition-error-translation'});
                    }, function () {
                        $state.go('communication-action-definition-error-translation');
                    });
                }]
            })
            .state('communication-action-definition-error-translation.edit', {
                parent : 'communication-action-definition-error-translation',
                url    : '/{id}/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication-action-definition-error-translation/communication-action-definition-error-translation-dialog.html',
                        controller  : 'CommunicationActionDefinitionErrorTranslationDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['CommunicationActionDefinitionErrorTranslation', function (CommunicationActionDefinitionErrorTranslation) {
                                return CommunicationActionDefinitionErrorTranslation.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('communication-action-definition-error-translation', null, {reload: 'communication-action-definition-error-translation'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('communication-action-definition-error-translation.delete', {
                parent : 'communication-action-definition-error-translation',
                url    : '/{id}/delete',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication-action-definition-error-translation/communication-action-definition-error-translation-delete-dialog.html',
                        controller  : 'CommunicationActionDefinitionErrorTranslationDeleteController',
                        controllerAs: 'vm',
                        size        : 'md',
                        resolve     : {
                            entity: ['CommunicationActionDefinitionErrorTranslation', function (CommunicationActionDefinitionErrorTranslation) {
                                return CommunicationActionDefinitionErrorTranslation.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('communication-action-definition-error-translation', null, {reload: 'communication-action-definition-error-translation'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();

(function () {
    'use strict';

    angular.module('eDocumentsApp').factory('SendGridService', SendGridService);

    SendGridService.$inject = ['$resource'];

    function SendGridService($resource) {
        return $resource(
      'api/sendgrid/:email',
      {},
            {
                removeFromList: {
                    method: 'DELETE',
                    url: 'api/sendgrid/removeFromUnsubscribeList/:email',
                },
                addToList: {
                    method: 'GET',
                    url: 'api/sendgrid/addToUnsubscribeList/:email',
                    isArray: false,
                },
                getStatus: {
                    method: 'GET',
                    url: 'api/sendgrid/getEmailStatus',
                    isArray: false,
                    transformResponse: function (data) {
                        return angular.fromJson(data);
                    },
                },
                getLanguageByEmail: {
                    method: 'GET',
                    url: 'api/sendgrid/getLanguageByEmail/:email',
                    isArray: false,
                    transformResponse: function (data) {
                        return {
                            responseData: data.toString(),
                        };
                    },
                },
            }
    );
    }
})();

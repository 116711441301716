(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('unit-of-measure', {
                parent: 'entity',
                url: '/unit-of-measure?page&sort&search',
                data: {
                    authorities: ['ROLE_SUPERADMIN'],
                    pageTitle: 'eDocumentsApp.unitOfMeasure.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/unit-of-measure/unit-of-measures.html',
                        controller: 'UnitOfMeasureController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'commonCode,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unitOfMeasure');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('unit-of-measure-detail', {
                parent: 'unit-of-measure',
                url: '/unit-of-measure/{id}',
                data: {
                    authorities: ['ROLE_SUPERADMIN'],
                    pageTitle: 'eDocumentsApp.unitOfMeasure.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/unit-of-measure/unit-of-measure-detail.html',
                        controller: 'UnitOfMeasureDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unitOfMeasure');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'UnitOfMeasure', function($stateParams, UnitOfMeasure) {
                        return UnitOfMeasure.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ['$state', function($state) {
                        return {
                            name: $state.current.name || 'unit-of-measure',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('unit-of-measure-detail.edit', {
                parent: 'unit-of-measure-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_SUPERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/unit-of-measure/unit-of-measure-dialog.html',
                        controller: 'UnitOfMeasureDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['UnitOfMeasure', function(UnitOfMeasure) {
                                return UnitOfMeasure.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('unit-of-measure.new', {
                parent: 'unit-of-measure',
                url: '/new',
                data: {
                    authorities: ['ROLE_SUPERADMIN']
                },
                onEnter: ['$state', '$uibModal', function($state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/unit-of-measure/unit-of-measure-dialog.html',
                        controller: 'UnitOfMeasureDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    commonCode: null,
                                    name: null,
                                    symbol: null,
                                    description: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('unit-of-measure', null, { reload: 'unit-of-measure' });
                    }, function() {
                        $state.go('unit-of-measure');
                    });
                }]
            })
            .state('unit-of-measure.edit', {
                parent: 'unit-of-measure',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SUPERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/unit-of-measure/unit-of-measure-dialog.html',
                        controller: 'UnitOfMeasureDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['UnitOfMeasure', function(UnitOfMeasure) {
                                return UnitOfMeasure.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('unit-of-measure', null, { reload: 'unit-of-measure' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('unit-of-measure.delete', {
                parent: 'unit-of-measure',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SUPERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/unit-of-measure/unit-of-measure-delete-dialog.html',
                        controller: 'UnitOfMeasureDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['UnitOfMeasure', function(UnitOfMeasure) {
                                return UnitOfMeasure.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('unit-of-measure', null, { reload: 'unit-of-measure' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();

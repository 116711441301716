(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationActionDefinitionErrorTranslationDialogController', CommunicationActionDefinitionErrorTranslationDialogController);

    CommunicationActionDefinitionErrorTranslationDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'CommunicationActionDefinitionErrorTranslation', 'CommunicationActionDefinition'];

    function CommunicationActionDefinitionErrorTranslationDialogController($timeout, $scope, $uibModalInstance, entity, CommunicationActionDefinitionErrorTranslation, CommunicationActionDefinition) {
        var vm = this;

        vm.communicationActionDefinitionErrorTranslation = entity;
        vm.clear                                         = clear;
        vm.save                                          = save;
        vm.communicationactiondefinitions                = CommunicationActionDefinition.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (angular.isDefined(vm.communicationActionDefinitionErrorTranslation.id) &&  vm.communicationActionDefinitionErrorTranslation.id !== null) {
                CommunicationActionDefinitionErrorTranslation.update(vm.communicationActionDefinitionErrorTranslation, onSaveSuccess, onSaveError);
            } else {
                CommunicationActionDefinitionErrorTranslation.save(vm.communicationActionDefinitionErrorTranslation, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:communicationActionDefinitionErrorTranslationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();

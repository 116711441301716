(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationDialogController', CommunicationDialogController);

    CommunicationDialogController.$inject = ['$timeout', '$scope',  '$uibModalInstance', 'entity', 'Communication', 'Document', 'CommunicationWorkflowStep'];

    function CommunicationDialogController ($timeout, $scope,  $uibModalInstance, entity, Communication, Document, CommunicationWorkflowStep) {
        var vm = this;

        vm.communication = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.documents = Document.query();
        vm.communicationworkflowsteps = CommunicationWorkflowStep.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (angular.isDefined(vm.communication.id) && vm.communication.id !== null) {
                Communication.update(vm.communication, onSaveSuccess, onSaveError);
            } else {
                Communication.save(vm.communication, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eDocumentsApp:communicationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.communicationTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('CommunicationDefinitionSearch', CommunicationDefinitionSearch);

    CommunicationDefinitionSearch.$inject = ['$resource'];

    function CommunicationDefinitionSearch($resource) {
        var resourceUrl =  'api/_search/communication-definitions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'searchList' : {
                method : 'GET',
                isArray: true,
                url: 'api/communication-definitions/list'
            },
        });
    }
})();

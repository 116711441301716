/* global _:true*/

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('DocumentDetailController', DocumentDetailController);

    DocumentDetailController.$inject = [
        'Principal',
        '$ngConfirm',
        '$scope',
        '$state',
        '$translate',
        'Document',
        'previousState',
        'entity',
        'CommunicationWorkflowStep',
        'Communication',
        'DataUtils',
        'Notification',
        'DocumentDownloadPdf',
        'CommunicationEntity',
        'CurrencyUtils'
    ];

    function DocumentDetailController(
        Principal,
        $ngConfirm,
        $scope,
        $state,
        $translate,
        Document,
        previousState,
        entity,
        CommunicationWorkflowStep,
        Communication,
        DataUtils,
        Notification,
        DocumentDownloadPdf,
        CommunicationEntity,
        CurrencyUtils
    ) {
        var vm = this;
        vm.document = entity;
        vm.previousState = previousState.name;
        vm.maximumOrder = undefined;
        vm.download = download;
        vm.canAdminRedoStep = canAdminRedoStep;
        vm.canRedoStep = canRedoStep;
        vm.redoStep = redoStep;
        vm.canAdminDoStep = canAdminDoStep;
        vm.canDoStep = canDoStep;
        vm.doStep = doStep;
        vm.downloaded = downloaded;
        vm.getCurrency = getCurrency;
        vm.getViewableFilename = DataUtils.getViewableFilename;
        vm.goBack = goBack;

        activate();

        function downloaded(id) {
            return vm.document.documentDownloads.filter(function(documentDownload) {
                return documentDownload.fileId === id;
            }).length;
        }

        function clear() {
            $state.go('document');
        }

        function fillObject(object, result) {
            object.companyName = result.name;
        }

        function fillInvoice(isSELFBILLED) {
            if (isSELFBILLED) {
                CommunicationEntity.get({ id: vm.document.originId }, function(result) {
                    fillObject(vm.invoice.receiver, result);
                    fillObject(vm.invoice.sender, result);
                });
            } else {
                if (!vm.invoice.sender.companyName) {
                    CommunicationEntity.get({ id: vm.document.originId }, function(result) {
                        fillObject(vm.invoice.sender, result);
                    });
                }
                if (!vm.invoice.receiver.companyName) {
                    CommunicationEntity.get({ id: vm.document.destinationId }, function(result) {
                        fillObject(vm.invoice.receiver, result);
                    });
                }
            }
        }

        function activate() {
            if (vm.document === null) {
                Notification.error({ message: 'Documento não encontrado!' });
                clear();
            } else {
                vm.approvalInfo = {
                    approvedBy: vm.document.approvedBy,
                    approvedDate: vm.document.approvedDate
                };

                if (vm.document.documentType === 'ORDER') {
                    Document.getOrder({ id: vm.document.id }).$promise.then(function(result) {
                        vm.order = result;
                        CommunicationEntity.findByGln({ gln: vm.order.header.supplier.gln }, function(result) {
                            vm.orderSupplier = result;
                        });
                        CommunicationEntity.findByGln({ gln: vm.order.header.buyer.gln }, function(result) {
                            vm.orderReceiver = result;
                        });
                    });
                } else {
                    Document.getInvoice({ id: vm.document.id }).$promise.then(function(result) {
                        vm.invoice = result;
                        if (
                            (vm.document.documentType === 'SELFBILLED' && !vm.invoice.receiver.companyName) ||
                            (vm.document.documentType !== 'SELFBILLED' && (!vm.invoice.sender.companyName || !vm.invoice.receiver.companyName))
                        ) {
                            fillInvoice(vm.document.documentType === 'SELFBILLED');
                        }
                    });
                }

                Principal.identity().then(function(account) {
                    vm.account = account;
                    for (var i = 0; i < vm.account.userEntities.length; i++) {
                        if ((vm.account.userEntities[i].entityId === vm.document.originId || vm.account.userEntities[i].entityId === vm.document.destinationId) && vm.account.userEntities[i].manager) {
                            vm.manager = true;
                            break;
                        }
                    }
                });
                Document.addView(vm.document.id);
                vm.currentWorkflow = {};
                CommunicationWorkflowStep.findByDefinition({ id: vm.document.communicationDefinitionId }).$promise.then(function(steps) {
                    vm.workflow = steps;
                    for (var i = 0; i < vm.workflow.length; i++) {
                        vm.currentWorkflow[vm.workflow[i].id] = {
                            order: vm.workflow[i].order,
                            id: vm.workflow[i].id,
                            executionTime: vm.workflow[i].originParameters.filter(function(originParameter) {
                                return originParameter.communicationParameterType === 'EXECUTION_TIME';
                            })[0],
                            description: vm.workflow[i].description,
                            name: vm.workflow[i].communicationActionDefinitionName,
                            executionType: vm.workflow[i].executionType,
                            communicationType: vm.workflow[i].communicationType
                        };
                        vm.maximumOrder = angular.isUndefined(vm.maximumOrder) || vm.workflow[i].order > vm.maximumOrder ? vm.workflow[i].order : vm.maximumOrder;
                    }
                    Communication.findByDocument({ id: vm.document.id }).$promise.then(function(communications) {
                        vm.communications = communications;
                        for (var j = 0; j < vm.communications.length; j++) {
                            var workflow = vm.currentWorkflow[vm.communications[j].communicationWorkflowStepId];
                            workflow.status = vm.communications[j].status;
                            workflow.previousStatus = vm.communications[j - 1] ? vm.communications[j - 1].status : undefined;
                            workflow.message = vm.communications[j].message;
                            workflow.code = vm.communications[j].code;
                            workflow.time = vm.communications[j].communicationTime;
                            vm.currentWorkflow[vm.communications[j].communicationWorkflowStepId] = workflow;
                        }
                        var array = [];
                        for (var objectKey in vm.currentWorkflow) {
                            if (vm.currentWorkflow.hasOwnProperty(objectKey) && angular.isObject(vm.currentWorkflow[objectKey]) && objectKey.charAt(0) !== '$') {
                                array.push(vm.currentWorkflow[objectKey]);
                            }
                        }
                        array.sort(function(a, b) {
                            a = a.order ? a.order : '0';
                            b = b.order ? b.order : '0';
                            return parseInt(a) - parseInt(b);
                        });
                        vm.currentWorkflow = array;
                    });
                });
            }
        }

        function getCurrency() {
            if (vm.invoice && vm.invoice.header) {
                return CurrencyUtils.getCurrency(vm.invoice.header.currencyCode);
            }
            if (vm.order && vm.order.header) {
                return CurrencyUtils.getCurrency(vm.order.header.currencyCode);
            }
            return '';
        }

        function download($event, fileId, fileType) {
            DocumentDownloadPdf.download($event, vm.document, fileId, fileType);
        }

        function canAdminRedoStep(step) {
            return !canRedoStep(step) // if we can already redo step as regular user we should not show the action again
                && step.status !== 'SCHEDULED' && step.order !== 1;
        }

        function canRedoStep(step) {
            if (step.order === vm.maximumOrder && (step.communicationType === 'EMAIL_OUT' || step.communicationType === 'UNEDIFACT_EMAIL_OUT' || (step.communicationType === 'MOLONI_ORDER_INTEGRATION' && step.code === 'ERR0059'))) { // if is a mail sending step and the last one
                return true;
            }
            if (vm.manager === true) {
                return step.order !== 1 && ((vm.document.status === 'ERROR' && step.status === 'ERROR') || (vm.document.status === 'WAITING' && step.status === 'WAITING'));
            }
            return false;
        }

        function redoStep(step) {
            $ngConfirm({
                title: $translate.instant('eDocumentsApp.document.detail.resend.title'),
                columnClass: 'medium',
                type: 'orange',
                animation: 'right',
                animationBounce: 1,
                closeAnimation: 'scale',
                content: $translate.instant('eDocumentsApp.document.detail.resend.message'),
                scope: $scope,
                buttons: {
                    confirm: {
                        text: $translate.instant('entity.action.confirm'),
                        btnClass: 'btn-warning',
                        keys: ['enter'],
                        action: function() {
                            Document.redoFromStep({
                                docId: vm.document.id,
                                stepId: step.id
                            }).$promise.then(function() {
                                $state.reload();
                            });
                        }
                    },
                    close: {
                        text: $translate.instant('entity.action.cancel'),
                        btnClass: 'btn-default',
                        keys: ['esc'],
                        action: function() {
                            // do nothing
                        }
                    }
                }
            });
        }

        function canAdminDoStep(step) {
            return step.executionType === 'MANUAL' && step.status === 'WAITING' && (!step.previousStatus || step.previousStatus === 'SUCCESS');
        }

        function canDoStep(step) {
            return (
                vm.document.status === 'WAITING' &&
                vm.manager === true &&
                step.executionType === 'MANUAL' &&
                step.status === 'WAITING' &&
                (!step.previousStatus || step.previousStatus === 'SUCCESS')
            );
        }

        function doStep(step) {
            $ngConfirm({
                title: $translate.instant('eDocumentsApp.document.detail.execute.title'),
                columnClass: 'medium',
                type: 'blue',
                animation: 'right',
                animationBounce: 1,
                closeAnimation: 'scale',
                content: $translate.instant('eDocumentsApp.document.detail.execute.message'),
                scope: $scope,
                buttons: {
                    confirm: {
                        text: $translate.instant('entity.action.confirm'),
                        btnClass: 'btn-blue',
                        keys: ['enter'],
                        action: function() {
                            Document.doFromStep({
                                docId: vm.document.id,
                                stepId: step.id
                            }).$promise.then(function() {
                                $state.reload();
                            });
                        }
                    },
                    close: {
                        text: $translate.instant('entity.action.cancel'),
                        btnClass: 'btn-default',
                        keys: ['esc'],
                        action: function() {
                            // do nothing
                        }
                    }
                }
            });
        }

        function goBack() {
            window.history.back();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ScheduledTaskDialogController', ScheduledTaskDialogController);

    ScheduledTaskDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'ScheduledTask', 'Document', 'CommunicationWorkflowStep'];

    function ScheduledTaskDialogController($timeout, $scope, $uibModalInstance, entity, ScheduledTask, Document, CommunicationWorkflowStep) {
        var vm = this;

        vm.scheduledTask = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.documents = Document.query();
        vm.communicationworkflowsteps = CommunicationWorkflowStep.query();

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.scheduledTask.id !== null) {
                ScheduledTask.update(vm.scheduledTask, onSaveSuccess, onSaveError);
            } else {
                ScheduledTask.save(vm.scheduledTask, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:scheduledTaskUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.executionDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .service('DocumentDownloadPdf', DocumentDownloadPdf);

    DocumentDownloadPdf.$inject = ['$uibModal', '$translate', 'DocumentFile', 'Document', 'DataUtils', 'Notification'];

    function DocumentDownloadPdf($uibModal, $translate, DocumentFile, Document, DataUtils, Notification) {
        this.download = function($event, document, fileId, fileType) {
            $event.preventDefault();
            Document.downloadNeedApproval({ id: document.id }).$promise.then(function(result) {
                var downloadNeedApproval = result.body;
                if ((isAnyDocumentOfType(document, 'SELFBILLED') || isAnyDocumentOfType(document, 'CREDITNOTE')) && !document.approved && downloadNeedApproval === 'true') {
                    $uibModal.open({
                        templateUrl: 'app/entities/document/document-approval-dialog.html',
                        controller: 'DocumentApprovalController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Document', function(Document) {
                                return Document.get({ id: document.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        Document.get({ id: document.id }).$promise.then(function(r) {
                            document.approved = r.approved;
                            document.approvedBy = r.approvedBy;
                            document.approvedDate = r.approvedDate;
                            DocumentFile.download({ id: fileId }).$promise.then(function(result) {
                                DataUtils.viewServerFile(result);
                                Document.addDownload({
                                    documentId: document.id,
                                    fileId: fileId,
                                    fileType: fileType
                                });
                                Document.addView(document.id);
                            }, function(err) {
                                processDownloadError(err);
                            });
                        }, function() {
                            Notification.error({ message: $translate.instant('eDocumentsApp.document.message.documentNotFound') });
                        });
                    });
                } else {
                    DocumentFile.download({ id: fileId }).$promise.then(function(result) {
                        Document.addDownload({
                            documentId: document.id,
                            fileId: fileId,
                            fileType: fileType
                        });
                        Document.addView(document.id);
                        DataUtils.viewServerFile(result);
                    }, function(err) {
                        processDownloadError(err);
                    });
                }
            }, function(e) {
                Notification.error({ message: $translate.instant(e.message) });
            });
        };

        this.downloadExternal = function($event, document, fileHashedId) {
            $event.preventDefault();
            Document.downloadNeedApprovalExternal({ hash: document.hashedId }).$promise.then(function(result) {
                var downloadNeedApproval = result.body;
                if ((isAnyDocumentOfType(document, 'SELFBILLED') || isAnyDocumentOfType(document, 'CREDITNOTE')) && !document.approved && downloadNeedApproval === 'true') {
                    $uibModal.open({
                        templateUrl: 'app/entities/document/document-approval-by-hashid-dialog.html',
                        controller: 'DocumentApprovalByHashIdController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Document', function(Document) {
                                return Document.getByExternalHash({ hash: document.hashedId }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        Document.getByExternalHash({ hash: document.hashedId }).$promise.then(function(r) {
                            document.approved = r.approved;
                            document.approvedBy = r.approvedBy;
                            document.approvedDate = r.approvedDate;

                            DocumentFile.externalDownload({ hash: fileHashedId }).$promise.then(function(result) {
                                DataUtils.viewServerFile(result);
                                DocumentFile.addExternalDownload(fileHashedId);
                            }, function(err) {
                                processDownloadError(err);
                            });
                        }, function() {
                            Notification.error({ message: $translate.instant('eDocumentsApp.document.message.documentNotFound') });
                        });
                    });
                } else {
                    DocumentFile.externalDownload({ hash: fileHashedId }).$promise.then(function(result) {
                        DataUtils.viewServerFile(result);
                        DocumentFile.addExternalDownload(fileHashedId);
                    }, function(err) {
                        processDownloadError(err);
                    });
                }
            }, function(e) {
                Notification.error({ message: $translate.instant(e.message) });
            });
        };

        function processDownloadError(error) {
            if (error.status === 404) {
                Notification.error({ message: $translate.instant('eDocumentsApp.document.message.documentNotFound') });
            } else {
                Notification.error({ message: $translate.instant('eDocumentsApp.document.message.errorDownloading') + ': ' + $translate.instant('error.' + DataUtils.getErrorMessageFromArrayBuffer(error.data.data)) });
            }
        }

        this.downloadAllFilesExternal = function($event, documentExternalAccessHash, documents) {
            $event.preventDefault();
            Document.downloadAllNeedApprovalExternal({ hash: documentExternalAccessHash }).$promise.then(function(result) {
                var downloadNeedApproval = result.body;

                if (downloadNeedApproval === 'true' && !areAllDocumentsApproved(documents)) {
                    Notification.error({ message: $translate.instant('eDocumentsApp.document.message.allMustBeApproved') });
                } else {
                    Document.downloadAllExternalAsZip({
                        hash: documentExternalAccessHash
                    }, function(result) {
                        if (result.filename !== null) {
                            Document.addAllExternalDownloads(documentExternalAccessHash);
                            DataUtils.viewServerFile(result);
                        } else {
                            Notification.error({ message: $translate.instant('eDocumentsApp.document.message.fileRetrievalError') });
                        }
                    }, function() {
                        Notification.error({ message: $translate.instant('eDocumentsApp.document.message.fileRetrievalError') });
                    });
                }
            }, function(e) {
                Notification.error({ message: $translate.instant(e.message) });
            });
        };

        /**
         * Checks whether one of the documents (or document, if passed object is not an array) is of passed document type
         *
         * @param documents
         * @param documentType
         * @returns {boolean|*}
         */
        function isAnyDocumentOfType(documents, documentType) {
            if (documents.documentType === documentType) {
                return true;
            }
            for (var i = 0; i < documents.length; i++) {
                if (documents[i].documentType === documentType) {
                    return true;
                }
            }
            return false;
        }

        /**
         * Checks whether all documents (or document, if passed object is not an array) are (is) approved but only if from specific type
         *
         * @param documents
         * @returns {boolean|*}
         */
        function areAllDocumentsApproved(documents) {
            if (documents.documentNumber) {
                if (isAnyDocumentOfType(documents, 'SELFBILLED') || isAnyDocumentOfType(documents, 'CREDITNOTE')) {
                    return documents.approved;
                }
                return true;
            }
            for (var i = 0; i < documents.length; i++) {
                if ((isAnyDocumentOfType(documents[i], 'SELFBILLED') || isAnyDocumentOfType(documents[i], 'CREDITNOTE')) && !documents[i].approved) {
                    return false;
                }
            }
            return true;
        }
    }
})();

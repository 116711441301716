(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('FavoriteSearchesController', FavoriteSearchesController);

    FavoriteSearchesController.$inject = ['$uibModalInstance', 'DocumentSearchByUser', 'filterBy', 'documentTypes', 'documentStates'];

    function FavoriteSearchesController($uibModalInstance, DocumentSearchByUser, filterBy, documentTypes, documentStates) {
        var vm = this;

        vm.clear = clear;
        vm.apply = apply;
        vm.tabSelected = tabSelected;
        vm.saveChanges = saveChanges;
        vm.reset = reset;
        vm.deleteRecord = deleteRecord;
        vm.filters = {
            currentPage: 0,
            itemsPerPage: 20,
            filterBy: angular.copy(filterBy()),
            orderBy: undefined,
            orderReverse: undefined
        };
        vm.name = undefined;
        vm.types = documentTypes;
        vm.states = documentStates;
        vm.changeFiles = changeFiles;
        vm.changeVisualization = changeVisualization;
        vm.changeDocumentType = changeDocumentType;
        vm.changeStatus = changeStatus;
        vm.addTab = addTab;
        vm.favoriteSearches = [];
        vm.active = undefined;
        loadAll();

        function loadAll() {
            DocumentSearchByUser.query({}, function(res) {
                vm.favoriteSearches = res;
            });
        }

        function apply() {
            $uibModalInstance.close(vm.filters.filterBy);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function tabSelected(tab) {
            vm.filters.filterBy = JSON.parse(tab.searchString);
            vm.name = tab.name;
        }

        function changeVisualization(value) {
            vm.filters.filterBy.documentViews.value = value;
        }

        function changeFiles(value) {
            vm.filters.filterBy.documentFiles.value = value;
        }

        function changeDocumentType(value) {
            vm.filters.filterBy.documentType.value = value;
        }

        function changeStatus(value) {
            vm.filters.filterBy.status.value = value;
        }

        function saveChanges(tab) {
            if (tab) {
                tab.searchString = JSON.stringify(vm.filters.filterBy);
                tab.name = vm.name;
            } else {
                tab = {
                    name: vm.name,
                    searchString: JSON.stringify(vm.filters.filterBy)
                };
            }
            DocumentSearchByUser.save(tab, function(res) {
                loadAll();
                vm.active = res.id;
            }, function() {
            });
        }

        function reset() {
            vm.filters.filterBy = angular.copy(filterBy());
        }

        function addTab() {
            vm.filters.filterBy = angular.copy(filterBy());
            vm.name = 'New';
        }

        function deleteRecord(tab) {
            DocumentSearchByUser.delete({ id: tab.id },
                function() {
                    loadAll();
                    vm.active = -1;
                });
        }
    }
})();

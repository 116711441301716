(function () {
    'use strict';
    angular
        .module('eDocumentsApp')
        .factory('CommunicationWorkflowStep', CommunicationWorkflowStep);

    CommunicationWorkflowStep.$inject = ['$resource', 'DateUtils'];

    function CommunicationWorkflowStep($resource, DateUtils) {
        var resourceUrl = 'api/communication-workflow-steps/:id';

        return $resource(resourceUrl, {}, {
            'query'           : {
                method : 'GET',
                isArray: true
            },
            'findByDefinition': {
                method : 'GET',
                url    : 'api/communication-workflow-steps-by-definition/:id',
                isArray: true
            },
            'get'             : {
                method           : 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.originParameters) {
                            for (var i = 0; i < data.originParameters.length; i++) {
                                if (data.originParameters[i].communicationParameterType === 'EXECUTION_TIME' || data.originParameters[i].communicationParameterType === 'EMAIL_EXECUTION_TIME' ||
                                    data.originParameters[i].communicationParameterType === 'SUSPENSION_START_TIME' || data.originParameters[i].communicationParameterType === 'SUSPENSION_END_TIME') {
                                    if(data.originParameters[i].value !== -1 && data.originParameters[i].value !== '-1') {
                                        data.originParameters[i].value = DateUtils.convertStringToTime(data.originParameters[i].value);
                                    } else {
                                        data.originParameters[i].value = undefined;
                                        data.originParameters[i].disableExecutionTime = true;
                                    }
                                }
                            }
                        }
                        if (data.destinationParameters) {
                            for (var j = 0; j < data.destinationParameters.length; j++) {
                                if (data.destinationParameters[j].communicationParameterType === 'EXECUTION_TIME' || data.destinationParameters[j].communicationParameterType === 'EMAIL_EXECUTION_TIME' ||
                                    data.destinationParameters[j].communicationParameterType === 'SUSPENSION_START_TIME' || data.destinationParameters[j].communicationParameterType === 'SUSPENSION_END_TIME') {
                                    if(data.destinationParameters[j].value !== -1 && data.destinationParameters[j].value !== '-1') {
                                        data.destinationParameters[j].value = DateUtils.convertStringToTime(data.destinationParameters[j].value);
                                    } else {
                                        data.destinationParameters[j].value = undefined;
                                        data.destinationParameters[j].disableExecutionTime = true;
                                    }
                                }
                            }
                        }
                    }
                    return data;
                }
            },
            'update'          : {
                method          : 'PUT',
                transformRequest: function (data) {
                    if (data && data.originParameters) {
                        for (var i = 0; i < data.originParameters.length; i++) {
                            if (data.originParameters[i].communicationParameterType === 'EXECUTION_TIME' || data.originParameters[i].communicationParameterType === 'EMAIL_EXECUTION_TIME' ||
                                data.originParameters[i].communicationParameterType === 'SUSPENSION_START_TIME' || data.originParameters[i].communicationParameterType === 'SUSPENSION_END_TIME') {
                                if(data.originParameters[i].value !== -1) {
                                    data.originParameters[i].value = DateUtils.convertTimeToString(data.originParameters[i].value);
                                }
                            }
                        }
                    }
                    if (data && data.destinationParameters) {
                        for (var j = 0; j < data.destinationParameters.length; j++) {
                            if (data.destinationParameters[j].communicationParameterType === 'EXECUTION_TIME' || data.destinationParameters[j].communicationParameterType === 'EMAIL_EXECUTION_TIME' ||
                                data.destinationParameters[j].communicationParameterType === 'SUSPENSION_START_TIME' || data.destinationParameters[j].communicationParameterType === 'SUSPENSION_END_TIME') {
                                if(data.destinationParameters[j].value !== -1) {
                                    data.destinationParameters[j].value = DateUtils.convertTimeToString(data.destinationParameters[j].value);
                                }
                            }
                        }
                    }
                    return angular.toJson(data);
                }
            }
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationDetailController', CommunicationDetailController);

    CommunicationDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'entity'];

    function CommunicationDetailController($scope, $rootScope, previousState, entity) {
        var vm = this;

        vm.communication = entity;
        vm.previousState = previousState.name;
        // eslint-disable-next-line
        var unsubscribe = $rootScope.$on('eDocumentsApp:communicationUpdate', function (event, result) {
            vm.communication = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

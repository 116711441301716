(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$localStorage', '$sessionStorage'];

    function authInterceptor($localStorage, $sessionStorage) {
        return {
            request: request
        };

        function request(config) {
            if (!config || !config.url || /^http/.test(config.url)) {
                return config;
            }

            /*jshint camelcase: false */
            config.headers = config.headers || {};
            var token      = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            return config;
        }
    }
})();

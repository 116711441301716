/* global $:true*/

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('SidebarController', SidebarController);

    SidebarController.$inject = [
        '$scope',
        '$timeout',
        '$window',
        '$state',
        'Auth',
        'Principal',
        'ProfileService',
        '$uibModal',
        'DocumentationUtils'
    ];

    function SidebarController($scope, $timeout, $window, $state, Auth, Principal, ProfileService, $uibModal, DocumentationUtils) {
        var vm = this;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.toggleSidebar = toggleSidebar;
        vm.downloadManual = download;
        vm.$state = $state;
        vm.config = {
            theme: 'minimal'
        };
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.knowMore = knowMore;

        function knowMore() {
            $uibModal
                .open({
                    templateUrl: 'app/layouts/footer/know-more/know-more-dialog.html',
                    controller: 'KnowMoreController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                })
                .result.then(
                function() {
                },
                function() {
                }
            );
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('landing-page');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function toggleSidebar() {
            $('#content').toggleClass('active');
            $('#sidebar').toggleClass('active');
            $('#sidebarCollapse').toggleClass('active');
            $('#navbar').toggleClass('active');
        }

        angular.element($window).on('click', function(event) {
            var target = $(event.target);
            var attr = target.attr('id');
            if (
                attr !== 'sidebar' &&
                attr !== 'sidebarCollapse' &&
                !$('#content').hasClass('active') &&
                !isDescendant($('#sidebarCollapse')[0], target[0]) &&
                !isDescendant($('#sidebar')[0], target[0])
            ) {
                toggleSidebar();
            }
        });

        function isDescendant(parent, child) {
            if (!parent || !child) return false;
            var node = child.parentNode;
            while (node != null) {
                if (node === parent) {
                    return true;
                }
                node = node.parentNode;
            }
            return false;
        }

        var start = null;

        addEventSlide();

        function addEventSlide() {
            window.addEventListener('touchstart', addTouchStart);
            window.addEventListener('touchend', addTouchEnd);
        }

        function addTouchStart(event) {
            if (event.touches.length === 1) {
                //just one finger touched
                start = event.touches.item(0).clientX;
            } else {
                start = null;
            }
        }

        function addTouchEnd(event) {
            var offset = 100; //at least 100px are a swipe
            if (start) {
                //just one finger touched
                var end = event.changedTouches.item(0).clientX;

                if (vm.isNavbarCollapsed === false && end > 250) {
                    auxClose();
                }
                if (end > start + offset) {
                    //a left -> right swipe
                    vm.isNavbarCollapsed = false;
                    $scope.$apply();
                    $timeout(function() {
                        openNav();
                    }, 250);
                }
                if (end < start - offset) {
                    auxClose();
                    //a right -> left swipe
                }
            }
        }

        function auxClose() {
            closeNav();
            $timeout(function() {
                vm.isNavbarCollapsed = true;
                $scope.$apply();
            }, 200);
        }

        function openNav() {
            if ($('#sidebar').hasClass('active')) {
                toggleSidebar();
            }
        }

        function closeNav() {
            if (!$('#sidebar').hasClass('active')) {
                toggleSidebar();
            }
        }

        function download() {
            DocumentationUtils.downloadUserManual();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('InvoiceLineSearch', InvoiceLineSearch);

    InvoiceLineSearch.$inject = ['$resource'];

    function InvoiceLineSearch($resource) {
        var resourceUrl =  'api/_search/invoice-lines/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').factory('ContactUsService', ContactUsService);

    ContactUsService.$inject = ['$uibModal'];

    function ContactUsService($uibModal) {
        var service = {
            open: open
        };

        var modalInstance = null;
        var resetModal = function() {
            modalInstance = null;
        };

        return service;

        function open() {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                backdrop: 'static',
                size: 'lg',
                animation: true,
                templateUrl: 'app/components/contact-us/contact-us.html',
                controller: 'ContactUsController',
                controllerAs: 'vm',
                resolve: {
                    translatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('login');
                            $translatePartialLoader.addPart('contactUs');
                            return $translate.refresh();
                        }
                    ]
                }
            });
            modalInstance.result.then(resetModal, resetModal);
        }
    }
})();

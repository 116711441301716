(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationController', CommunicationController);

    CommunicationController.$inject = ['Communication', 'ParseLinks', 'Notification', '$state', 'communicationDefinition', 'paginationConstants', 'workflowStep', 'pagingParams', '$translate'];

    function CommunicationController(Communication, ParseLinks, Notification, $state, communicationDefinition, paginationConstants, workflowStep, pagingParams, $translate) {
        var vm = this;
        vm.communications = [];
        vm.communicationDefinition = communicationDefinition;
        vm.workflowStep = workflowStep;
        vm.predicate = pagingParams.predicate;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.loadPage = loadPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.transition = transition;

        loadAll();

        function loadAll() {
            if (vm.workflowStep) {
                Communication.findByWorkflowStep({
                    id: vm.workflowStep.id,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else if (vm.communicationDefinition) {
                Communication.findByDefinition({
                    id: vm.communicationDefinition.id,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Notification.warning({
                    message: $translate.instant('eDocumentsApp.communication.messages.noContextDefined'),
                    delay: 5000
                });
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.communications = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                Notification.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ExternalEntityDialogController', ExternalEntityDialogController);

    ExternalEntityDialogController.$inject = ['$timeout', '$scope', 'readOnly', '$state', 'entity', 'ExternalEntity', 'CommunicationEntity'];

    function ExternalEntityDialogController($timeout, $scope, readOnly, $state, entity, ExternalEntity, CommunicationEntity) {
        var vm = this;

        vm.externalEntity = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.communicationentities = CommunicationEntity.query();
        vm.readOnly = readOnly;
        vm.goBack = goBack;

        activate();

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });


        function activate() {
            if (!vm.externalEntity) {
                clear();
            }
        }

        function clear() {
            $state.go('external-entity');
        }

        function save() {
            vm.isSaving = true;
            if (vm.externalEntity.id !== null) {
                ExternalEntity.update(vm.externalEntity, onSaveSuccess, onSaveError);
            } else {
                ExternalEntity.save(vm.externalEntity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:externalEntityUpdate', result);
            $state.go('external-entity');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function goBack() {
            window.history.back();
        }
    }
})();

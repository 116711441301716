(function() {
    'use strict';
    angular.module('eDocumentsApp').factory('AmaSafeCredential', AmaSafeCredential);

    AmaSafeCredential.$inject = ['$resource'];

    function AmaSafeCredential($resource) {
        var resourceUrl = 'api/ama-safe-credentials/:id';

        return $resource(resourceUrl, {}, {
            query: { method: 'GET', isArray: true }, create: {
                method: 'POST'
            }, findByEntity: {
                method: 'GET',
                url: 'api/ama-safe-credentials-for-communication-entity/:communicationEntityId',
                isArray: true
            }, delete: { method: 'DELETE' }
        });
    }
})();

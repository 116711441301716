/* global $:true*/
(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('NavbarController', NavbarController).value('duScrollDuration', 1500).value('duScrollOffset', 80);

    NavbarController.$inject = ['$scope', '$state', '$stateParams', '$timeout', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'ContactUsService'];

    function NavbarController($scope, $state, $stateParams, $timeout, Auth, Principal, ProfileService, LoginService, ContactUsService) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.login = login;
        vm.logout = logout;
        vm.toggleSidebar = toggleSidebar;
        vm.changeStyle = changeStyle;
        vm.contactUs = contactUs;
        vm.$state = $state;
        init();

        function init() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account) {
                    if (vm.account.imageContentType && vm.account.image) {
                        vm.account.imageURL = 'data:' + vm.account.imageContentType + ';base64,' + vm.account.image;
                    }
                }
            });
        }

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
        $scope.$on('authenticationSuccess', function() {
            Principal.identity(true).then(function(account) {
                vm.account = account;
                if (vm.account) {
                    if (vm.account.imageContentType && vm.account.image) {
                        vm.account.imageURL = 'data:' + vm.account.imageContentType + ';base64,' + vm.account.image;
                    }
                }
            });
        });

        $scope.$on('updateAccountSuccess', function() {
            Principal.identity(true).then(function(account) {
                vm.account = account;
                if (vm.account) {
                    if (vm.account.imageContentType && vm.account.image) {
                        vm.account.imageURL = 'data:' + vm.account.imageContentType + ';base64,' + vm.account.image;
                    }
                }
            });
        });

        function changeStyle(style) {
            $('body').removeClass('dark');
            $('body').removeClass('white');
            $('body').removeClass('light');
            $('body').addClass(style);
        }

        function login() {
            LoginService.open();
        }

        function logout() {
            Auth.logout();
            $state.go('landing-page');
        }

        toggleSidebar();

        function toggleSidebar() {
            $('#content').toggleClass('active');
            $('#sidebar').toggleClass('active');
            $('#sidebarCollapse').toggleClass('active');
            $('#navbar').toggleClass('active');
        }

        function contactUs() {
            ContactUsService.open();
        }

        $timeout(function() {
            if ($stateParams.section) {
                goToSection($stateParams.section);
            }
        }, 500);

        function goToSection(section) {
            $('#' + section + 'NavLink').click();
        }
    }
})();

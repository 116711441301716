(function() {
    'use strict';
    angular
        .module('eDocumentsApp')
        .factory('ExternalEntity', ExternalEntity);

    ExternalEntity.$inject = ['$resource', 'DateUtils'];

    function ExternalEntity($resource, DateUtils) {
        var resourceUrl = 'api/external-entities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'synchronize': { method: 'PUT', url: 'api/external-entities-synchronize' },
            transformResponse: function(data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            },
            'getLastSynchronization': {
                method: 'GET', url: 'api/external-entity-synchronizing-entities/:communicationEntityId',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'activate': { method: 'PUT', url: 'api/external-entities-activate' },
            'inactivate': { method: 'PUT', url: 'api/external-entities-inactivate' }
        });
    }
})();

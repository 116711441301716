(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('AmaSafeCredentialDisclaimerDialogController', AmaSafeCredentialDisclaimerDialogController);

    AmaSafeCredentialDisclaimerDialogController.$inject = ['$uibModalInstance'];

    function AmaSafeCredentialDisclaimerDialogController($uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.accept = accept;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function accept() {
            $uibModalInstance.close();
        }
    }
})();

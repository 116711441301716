(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('communication', {
                parent : 'app',
                url    : '/communication',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.communication.home.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/communication/communications.html',
                        controller  : 'CommunicationController',
                        controllerAs: 'vm'
                    }
                },
                params : {
                    page  : {
                        value : '1',
                        squash: true
                    },
                    wid   : null,
                    did   : null,
                    sort  : {
                        value : 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams           : ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page     : PaginationUtil.parsePage($stateParams.page),
                            sort     : $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search   : $stateParams.search
                        };
                    }],
                    communicationDefinition: ['$stateParams', 'CommunicationDefinition', function ($stateParams, CommunicationDefinition) {
                        if ($stateParams.did) {
                            return CommunicationDefinition.get({id: $stateParams.did}).$promise;
                        }
                        return undefined;
                    }],
                    workflowStep                 : ['$stateParams', 'CommunicationWorkflowStep', function ($stateParams, CommunicationWorkflowStep) {
                        if ($stateParams.wid) {
                            return CommunicationWorkflowStep.get({id: $stateParams.wid}).$promise;
                        }
                        return undefined;
                    }],
                    translatePartialLoader : ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('communication');
                        $translatePartialLoader.addPart('communicationStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('communication-detail', {
                parent : 'communication',
                url    : '/communication/{id}',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.communication.detail.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/communication/communication-detail.html',
                        controller  : 'CommunicationDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('communication');
                        $translatePartialLoader.addPart('communicationStatus');
                        return $translate.refresh();
                    }],
                    entity                : ['$stateParams', 'Communication', function ($stateParams, Communication) {
                        return Communication.get({id: $stateParams.id}).$promise;
                    }],
                    previousState         : ["$state", function ($state) {
                        return {
                            name  : $state.current.name || 'communication',
                            params: $state.params,
                            url   : $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('communication-detail.edit', {
                parent : 'communication-detail',
                url    : '/detail/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication/communication-dialog.html',
                        controller  : 'CommunicationDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['Communication', function (Communication) {
                                return Communication.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('communication.new', {
                parent : 'communication',
                url    : '/new',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$state', '$uibModal', function ($state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication/communication-dialog.html',
                        controller  : 'CommunicationDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: function () {
                                return {
                                    communicationTime: null,
                                    status           : null,
                                    message          : null,
                                    id               : null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('communication', null, {reload: 'communication'});
                    }, function () {
                        $state.go('communication');
                    });
                }]
            })
            .state('communication.edit', {
                parent : 'communication',
                url    : '/{id}/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication/communication-dialog.html',
                        controller  : 'CommunicationDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['Communication', function (Communication) {
                                return Communication.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('communication', null, {reload: 'communication'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('communication.delete', {
                parent : 'communication',
                url    : '/{id}/delete',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication/communication-delete-dialog.html',
                        controller  : 'CommunicationDeleteController',
                        controllerAs: 'vm',
                        size        : 'md',
                        resolve     : {
                            entity: ['Communication', function (Communication) {
                                return Communication.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('communication', null, {reload: 'communication'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();

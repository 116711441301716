(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$uibModal'];

    function LoginService($uibModal) {
        var service = {
            open: open,
            reset: reset
        };

        var modalInstance = null;
        var modalInstance2 = null;
        var resetModal = function () {
            modalInstance = null;
            modalInstance2 = null;
        };

        return service;

        function open() {
            if (modalInstance2 !== null) modalInstance2.close();
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(resetModal, resetModal);
            modalInstance2.result.then(resetModal, resetModal);
        }

        function reset() {
            if (modalInstance !== null) modalInstance.close();
            if (modalInstance2 !== null) return;
            modalInstance2 = $uibModal.open({
                animation: true,
                templateUrl: 'app/account/reset/request/reset.request.html',
                controller: 'RequestResetController',
                controllerAs: 'vm',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reset');
                        $translatePartialLoader.addPart('login');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(resetModal, resetModal);
            modalInstance2.result.then(resetModal, resetModal);
        }
    }
})();

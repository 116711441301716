(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('FAQsController', FAQsController);

    FAQsController.$inject = ['ContactUsService', 'JhiLanguageService'];

    function FAQsController(ContactUsService, JhiLanguageService) {
        var vm = this;
        vm.contactUs = contactUs;
        vm.animateToCardHeader = animateToCardHeader;
        vm.getCurrentLanguageIcon = getCurrentLanguageIcon;

        function contactUs() {
            ContactUsService.open();
        }

        function animateToCardHeader() {
            $('.collapse').on('shown.bs.collapse', function() {
                var $card = $(this).closest('.card');
                $('html,body').animate(
                    {
                        scrollTop: $card.offset().top - 82
                    },
                    250
                );
            });
        }

        angular.element(document).ready(function() {
            setTimeout(animateToCardHeader, 500);
        });

        function getCurrentLanguageIcon() {
            return JhiLanguageService.getCurrentLanguageIcon();
        }
    }
})();

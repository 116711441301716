(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('LogsService', LogsService);

    LogsService.$inject = ['$resource'];

    function LogsService ($resource) {
        return $resource('management/logs', {}, {
            'findAll'    : {
                method : 'GET',
                isArray: true
            },
            'changeLevel': {method: 'PUT'}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('CommunicationEntitySearch', CommunicationEntitySearch);

    CommunicationEntitySearch.$inject = ['$resource'];

    function CommunicationEntitySearch($resource) {
        var resourceUrl =  'api/_search/communication-entities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

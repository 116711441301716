(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('CommunicationEntityDialogController', CommunicationEntityDialogController);

    CommunicationEntityDialogController.$inject = [
        'Notification',
        '$ngConfirm',
        '$uibModal',
        '$state',
        'readOnly',
        '$timeout',
        '$scope',
        '$translate',
        'entity',
        'CommunicationEntity',
        'CommunicationParameter',
        'User',
        'AmaSafeCredential',
        'Principal',
        'ContactUsService'
    ];

    function CommunicationEntityDialogController(
        Notification,
        $ngConfirm,
        $uibModal,
        $state,
        readOnly,
        $timeout,
        $scope,
        $translate,
        entity,
        CommunicationEntity,
        CommunicationParameter,
        User,
        AmaSafeCredential,
        Principal,
        ContactUsService
    ) {
        var vm = this;
        vm.currentAccount = null;
        vm.communicationEntity = entity;
        vm.readOnly = readOnly;
        vm.selectOriginCommunicationType = undefined;
        vm.selectOriginCommunicationParameterType = undefined;
        vm.selectDestinationCommunicationType = undefined;
        vm.selectDestinationCommunicationParameterType = undefined;
        vm.showMissingVatRegistrationNumber = false;
        vm.selectCommunicationEntityConnectorParametersNames = undefined;
        vm.isUserAllowedToEditVATRegistrationNumber = isUserAllowedToEditVATRegistrationNumber;
        vm.clear = clear;
        vm.save = save;
        vm.editUser = editUser;
        vm.communicationDefinitionAuthority = communicationDefinitionAuthority;
        vm.changeOriginParameter = changeOriginParameter;
        vm.changeDestinationParameter = changeDestinationParameter;
        vm.addUser = addUser;
        vm.removeFromEntity = removeFromEntity;
        vm.contactUs = contactUs;
        vm.addCredential = addCredential;
        vm.deleteCredential = deleteCredential;
        vm.addOriginParameter = addOriginParameter;
        vm.addDestinationParameter = addDestinationParameter;
        vm.addcommunicationEntityConnectorParameter = addcommunicationEntityConnectorParameter;
        vm.toggleManager = toggleManager;

        vm.communicationEntityConnectorParametersNames = ['USERNAME', 'PASSWORD', 'COMPANY', 'CLIENT', 'SECRET', 'API_URL', 'INSTANCE', 'LINE',
            'NUMBEROFDAYS', 'DOCTYPES', 'DOCUMENTLISTAPI', 'DOCUMENTDETAILAPI', 'DOCUMENTPDFAPI', 'NUMBERINGSERIES', 'ATCERTIFICATENUMBER'];
        vm.changeExternalConnector = changeExternalConnector;


        vm.findUser = findUser;
        vm.deleteCommunicationDestinationParameter = deleteCommunicationDestinationParameter;
        vm.deleteCommunicationOriginParameter = deleteCommunicationOriginParameter;
        vm.deleteCommunicationEntityConnectorParameter = deleteCommunicationEntityConnectorParameter;
        vm.goBack = goBack;

        activate();

        vm.communicationEntityConnectorType = vm.communicationEntity.entityConnector;

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function activate() {
            if (!vm.communicationEntity) {
                clear();
            } else {
                vm.communicationTypes = CommunicationParameter.getCommunicationTypesForCommunicationEntity({ id: vm.communicationEntity.id });
                getEntityUsers();
                getAmaSafeCredentials();
            }
        }

        function getEntityUsers() {
            User.findByEntity({ id: vm.communicationEntity.id }).$promise.then(function(res) {
                vm.users = [];
                for (var i = 0; i < res.length; i++) {
                    for (var j = 0; j < res[i].userEntities.length; j++) {
                        if (res[i].userEntities[j].entityId === vm.communicationEntity.id) {
                            res[i].manager = res[i].userEntities[j].manager == null ? false : res[i].userEntities[j].manager;
                            res[i].emitter = res[i].userEntities[j].emitter == null ? false : res[i].userEntities[j].emitter;
                            res[i].receptor = res[i].userEntities[j].receptor == null ? false : res[i].userEntities[j].receptor;
                            vm.users.push(res[i]);
                        }
                    }
                }
            });
        }

        function getAmaSafeCredentials() {
            AmaSafeCredential.findByEntity({ communicationEntityId: vm.communicationEntity.id }).$promise.then(function(res) {
                vm.amaSafeCredentials = res;
            });
        }

        function clear() {
            $state.go('communication-entity');
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function isUserAllowedToEditVATRegistrationNumber() {
            if (vm.currentAccount) {
                return (
                    vm.currentAccount.authorities.includes('ROLE_SUPERADMIN') ||
                    vm.currentAccount.authorities.includes('ROLE_ADMIN') ||
                    vm.currentAccount.authorities.includes('ROLE_PARTNER')
                );
            }
            return false;
        }

        function checkTimeParams(communicationEntity) {
            if (communicationEntity.originParameters) {
                communicationEntity.originParameters.forEach(function(parameter) {
                    if (
                        parameter.communicationParameterType === 'EMAIL_EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_START_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_END_TIME'
                    ) {
                        if (parameter.disableExecutionTime) {
                            parameter.value = -1;
                        }
                    }
                });
            }
            if (communicationEntity.destinationParameters) {
                communicationEntity.destinationParameters.forEach(function(parameter) {
                    if (
                        parameter.communicationParameterType === 'EMAIL_EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_START_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_END_TIME'
                    ) {
                        if (parameter.disableExecutionTime) {
                            parameter.value = -1;
                        }
                    }
                });
            }
        }

        function save() {
            vm.isSaving = true;
            checkTimeParams(vm.communicationEntity);
            if (vm.communicationEntity.entityConnector === 'NO_CONNECTOR') {
                vm.communicationEntity.communicationEntityConnectorParameters = [];
            }
            if (angular.isDefined(vm.communicationEntity.id) && vm.communicationEntity.id !== null) {
                CommunicationEntity.update(vm.communicationEntity, onSaveSuccess, onSaveError);
            } else {
                CommunicationEntity.save(vm.communicationEntity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:communicationEntityUpdate', result);
            $state.go('communication-entity');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function toggleManager(user, manager) {
            User.updateUserEntity(
                {
                    userId: user.id,
                    entityId: vm.communicationEntity.id,
                    manager: !manager,
                    emitter: user.emitter,
                    receptor: user.receptor
                },
                function() {
                    Notification.success({ message: $translate.instant('userManagement.message.userUpdated') });
                    getEntityUsers();
                },
                function() {
                    Notification.error({ message: $translate.instant('userManagement.message.errorUpdatingUser') });
                }
            );
        }

        function addUser() {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/entities/communication-entity/communication-entity-user.modal.html',
                controller: 'CommunicationEntityUserDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        var user = new User();
                        user.activated = true;
                        user.id = null;
                        user.authorities = ['ROLE_USER'];
                        user.langKey = 'pt-pt';
                        return user;
                    }
                }
            });
            modalInstance.result.then(
                function(user) {
                    Notification.success({ message: $translate.instant('userManagement.message.createdUserAddedToEntity') });
                    addUserToEntity(user.id);
                    getEntityUsers();
                },
                function() {
                    Notification.error({ message: $translate.instant('userManagement.message.errorAddingCreatedUserToEntity') });
                }
            );
        }

        function editUser(user) {
            $uibModal.open({
                templateUrl: 'app/entities/communication-entity/communication-entity-user.modal.html',
                controller: 'CommunicationEntityUserDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return user;
                    }
                }
            });
        }

        function communicationDefinitionAuthority(user) {
            $uibModal.open({
                templateUrl: 'app/entities/communication-entity/communication-authorities-dialog.html',
                controller: 'CommunicationAuthoritiesDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    user: function() {
                        return user;
                    },
                    entity: function() {
                        return vm.communicationEntity.id;
                    },
                    readOnly: function() {
                        return vm.readOnly;
                    }
                }
            });
        }

        function removeFromEntity(user) {
            User.removeEntityToUser(
                {
                    userId: user.id,
                    entityId: vm.communicationEntity.id
                },
                function() {
                    Notification.success({ message: $translate.instant('userManagement.message.userRemovedFromEntity') });
                    getEntityUsers();
                },
                function() {
                    Notification.error({ message: $translate.instant('userManagement.message.errorRemovingUserFromEntity') });
                }
            );
        }

        function addUserToEntity(id) {
            User.addEntityToUser(
                {
                    userId: id,
                    entityId: vm.communicationEntity.id,
                    manager: false
                },
                function() {
                    Notification.success({ message: $translate.instant('userManagement.message.userAddedToEntity') });
                    getEntityUsers();
                },
                function() {
                    Notification.error({ message: $translate.instant('userManagement.message.errorAddingUserToEntity') });
                }
            );
        }

        function findUser() {
            // noinspection JSUnusedGlobalSymbols
            $ngConfirm({
                title: $translate.instant('userManagement.creationDialog.question'),
                type: 'blue',
                animation: 'zoom',
                closeAnimation: 'scale',
                contentUrl: 'app/entities/communication-entity/user-question.html',
                buttons: {
                    cancel: {
                        text: $translate.instant('entity.action.cancel'),
                        disabled: false,
                        btnClass: 'btn-default',
                        action: function() {
                        }
                    },
                    search: {
                        text: $translate.instant('entity.action.search'),
                        disabled: true,
                        btnClass: 'btn-primary',
                        action: function(scope) {
                            User.findByEmail({ email: scope.email }).$promise.then(
                                function(res) {
                                    if (res.id) {
                                        if (!res.userEntities) {
                                            res.userEntities = [];
                                        } else {
                                            for (var idx in res.userEntities) {
                                                if (res.userEntities[idx].entityId === vm.communicationEntity.id) {
                                                    Notification.error({ message: $translate.instant('userManagement.creationDialog.userAlreadyInEntity') });
                                                    return;
                                                }
                                            }
                                        }
                                        addUserToEntity(res.id);
                                    } else {
                                        Notification.error({
                                            title: $translate.instant('userManagement.creationDialog.userNotFound'),
                                            message: $translate.instant('userManagement.creationDialog.shouldCreateUser')
                                        });
                                        addUser();
                                    }
                                },
                                function() {
                                    Notification.error({
                                        title: $translate.instant('userManagement.creationDialog.userNotFound'),
                                        message: $translate.instant('userManagement.creationDialog.shouldCreateUser')
                                    });
                                    addUser();
                                }
                            );
                        }
                    }
                },
                onScopeReady: function(scope) {
                    // eslint-disable-next-line angular/controller-as-vm
                    var self = this;
                    scope.textChange = function() {
                        if (scope.email) {
                            self.buttons.search.setDisabled(false);
                        } else {
                            self.buttons.search.setDisabled(true);
                        }
                    };
                }
            });
        }

        function contactUs() {
            ContactUsService.open();
        }

        function addCredential() {
            if (!vm.communicationEntity || !vm.communicationEntity.vatRegistrationNumber || vm.communicationEntity.vatRegistrationNumber === '') {
                vm.showMissingVatRegistrationNumber = true;
            } else {
                if (vm.amaSafeCredentials[0] && vm.amaSafeCredentials[0].status === 'ACTIVE') {
                    // if there is any active credential, it's always the first one
                    $uibModal
                        .open({
                            templateUrl: 'app/entities/ama-safe-credential/ama-safe-credential-add-confirmation-dialog.html',
                            controller: 'AmaSafeCredentialAddConfirmationDialogController',
                            controllerAs: 'vm',
                            size: 'md'
                        })
                        .result.then(
                        function() {
                            $state.go('ama-safe-credential-new', { communicationEntityId: vm.communicationEntity.id });
                        },
                        function() {
                        }
                    );
                } else {
                    $state.go('ama-safe-credential-new', { communicationEntityId: vm.communicationEntity.id });
                }
            }
        }

        function deleteCredential(amaSafeCredential) {
            $uibModal
                .open({
                    templateUrl: 'app/entities/ama-safe-credential/ama-safe-credential-delete-dialog.html',
                    controller: 'AmaSafeCredentialDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        amaSafeCredential: amaSafeCredential
                    }
                })
                .result.then(
                function() {
                    AmaSafeCredential.delete(
                        {
                            id: amaSafeCredential.id
                        },
                        function() {
                            Notification.success({ message: $translate.instant('eDocumentsApp.amaSafeCredential.message.success.delete') });
                            getAmaSafeCredentials();
                        },
                        function() {
                            Notification.error({ message: $translate.instant('eDocumentsApp.amaSafeCredential.message.error.delete') });
                            getAmaSafeCredentials();
                        }
                    );
                },
                function() {
                }
            );
        }

        function changeOriginParameter() {
            vm.communicationParameterTypes = vm.communicationTypes[vm.selectOriginCommunicationType];
        }

        function changeDestinationParameter() {
            vm.communicationParameterTypes = vm.communicationTypes[vm.selectDestinationCommunicationType];
        }

        function deleteCommunicationOriginParameter(communicationParameter) {
            for (var i = 0, len = vm.communicationEntity.originParameters.length; i < len; i++) {
                if (vm.communicationEntity.originParameters[i] === communicationParameter) {
                    vm.communicationEntity.originParameters.splice(i, 1);
                }
            }
        }

        function deleteCommunicationDestinationParameter(communicationParameter) {
            for (var i = 0, len = vm.communicationEntity.destinationParameters.length; i < len; i++) {
                if (vm.communicationEntity.destinationParameters[i] === communicationParameter) {
                    vm.communicationEntity.destinationParameters.splice(i, 1);
                }
            }
        }

        function addOriginParameter() {
            if (vm.selectOriginCommunicationType && vm.selectOriginCommunicationParameterType) {
                var communicationParameter = {
                    communicationType: vm.selectOriginCommunicationType,
                    communicationParameterType: vm.selectOriginCommunicationParameterType,
                    value: ''
                };
                if (!isCommunicationParameterInCommunicationEntityOriginParametersArray(communicationParameter)) {
                    vm.communicationEntity.originParameters.push(communicationParameter);
                }
            }
        }

        function addDestinationParameter() {
            if (vm.selectDestinationCommunicationType && vm.selectDestinationCommunicationParameterType) {
                var communicationParameter = {
                    communicationType: vm.selectDestinationCommunicationType,
                    communicationParameterType: vm.selectDestinationCommunicationParameterType,
                    value: ''
                };
                if (!isCommunicationParameterInCommunicationEntityDestinationParametersArray(communicationParameter)) {
                    vm.communicationEntity.destinationParameters.push(communicationParameter);
                }
            }
        }

        function isCommunicationParameterInCommunicationEntityOriginParametersArray(communicationParameter) {
            if (angular.isUndefined(vm.communicationEntity.originParameters)) {
                vm.communicationEntity.originParameters = [];
            }
            for (var i = 0, len = vm.communicationEntity.originParameters.length; i < len; i++) {
                if (
                    vm.communicationEntity.originParameters[i].communicationType === communicationParameter.communicationType &&
                    vm.communicationEntity.originParameters[i].communicationParameterType === communicationParameter.communicationParameterType
                ) {
                    return true;
                }
            }
            return false;
        }

        function isCommunicationParameterInCommunicationEntityDestinationParametersArray(communicationParameter) {
            if (angular.isUndefined(vm.communicationEntity.destinationParameters)) {
                vm.communicationEntity.destinationParameters = [];
            }
            for (var i = 0, len = vm.communicationEntity.destinationParameters.length; i < len; i++) {
                if (
                    vm.communicationEntity.destinationParameters[i].communicationType === communicationParameter.communicationType &&
                    vm.communicationEntity.destinationParameters[i].communicationParameterType === communicationParameter.communicationParameterType
                ) {
                    return true;
                }
            }
            return false;
        }

        function addcommunicationEntityConnectorParameter() {
            if (vm.communicationEntityConnectorType && vm.selectCommunicationEntityConnectorParametersNames) {
                var communicationEntityConnectorParameter = {
                    parameterName: vm.selectCommunicationEntityConnectorParametersNames,
                    parameterValue: '',
                    comminicationEntityId: vm.communicationEntity.id
                };
                if (!isConnectorParameterInCommunicationEntityConnectorParametersArray(communicationEntityConnectorParameter)) {
                    vm.communicationEntity.communicationEntityConnectorParameters.push(communicationEntityConnectorParameter);
                }
            }
        }

        function isConnectorParameterInCommunicationEntityConnectorParametersArray(communicationEntityConnectorParameter) {
            if (angular.isUndefined(vm.communicationEntity.communicationEntityConnectorParameters)) {
                vm.communicationEntity.communicationEntityConnectorParameters = [];
            }
            for (var i = 0, len = vm.communicationEntity.communicationEntityConnectorParameters.length; i < len; i++) {
                if (
                    vm.communicationEntity.communicationEntityConnectorParameters[i].parameterName === communicationEntityConnectorParameter.parameterName &&
                    vm.communicationEntity.communicationEntityConnectorParameters[i].comminicationEntityId === communicationEntityConnectorParameter.comminicationEntityId
                ) {
                    return true;
                }
            }
            return false;
        }

        function deleteCommunicationEntityConnectorParameter(communicationEntityConnectorParameters) {
            for (var i = 0, len = vm.communicationEntity.communicationEntityConnectorParameters.length; i < len; i++) {
                if (vm.communicationEntity.communicationEntityConnectorParameters[i] === communicationEntityConnectorParameters) {
                    vm.communicationEntity.communicationEntityConnectorParameters.splice(i, 1);
                }
            }
        }

        function changeExternalConnector() {
            vm.communicationEntityConnectorType = event.target.value;
        }

        function goBack() {
            window.history.back();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('external-entity', {
                parent: 'entity', url: '/external-entity/{ceid}?&page&sort&search', data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
                    pageTitle: 'eDocumentsApp.externalEntity.home.title'
                }, views: {
                    'content@': {
                        templateUrl: 'app/entities/external-entity/external-entities.html',
                        controller: 'ExternalEntityController',
                        controllerAs: 'vm'
                    }
                }, params: {
                    page: {
                        value: '1', squash: true
                    }, ceid: null, sort: {
                        value: 'id,asc', squash: true
                    }, search: null
                }, resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    communicationEntity: ['$stateParams', 'CommunicationEntity', function($stateParams, CommunicationEntity) {
                        if ($stateParams.ceid) {
                            return CommunicationEntity.get({ id: $stateParams.ceid }).$promise;
                        }
                        return undefined;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('externalEntity');
                        $translatePartialLoader.addPart('externalEntityStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('external-entity-detail', {
                parent: 'external-entity', url: '/external-entity', data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
                    pageTitle: 'eDocumentsApp.externalEntity.detail.title'
                }, views: {
                    'content@': {
                        templateUrl: 'app/entities/external-entity/external-entity-dialog.html',
                        controller: 'ExternalEntityDialogController',
                        controllerAs: 'vm'
                    }
                }, params: {
                    id: null, ceid: null
                }, resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('externalEntity');
                        $translatePartialLoader.addPart('externalEntityStatus');
                        return $translate.refresh();
                    }], entity: ['$stateParams', 'ExternalEntity', function($stateParams, ExternalEntity) {
                        if ($stateParams.id != null) {
                            return ExternalEntity.get({ id: $stateParams.id }).$promise;
                        }
                        return null;
                    }], readOnly: function() {
                        return true;
                    }, previousState: ['$state', function($state) {
                        return {
                            name: $state.current.name || 'external-entity',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('external-entity-detail.edit', {
                parent: 'external-entity-detail', url: '/detail/edit', data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                }, views: {
                    'content@': {
                        templateUrl: 'app/entities/external-entity/external-entity-dialog.html',
                        controller: 'ExternalEntityDialogController',
                        controllerAs: 'vm'
                    }
                }, resolve: {
                    entity: ['$stateParams', 'ExternalEntity', function($stateParams, ExternalEntity) {
                        return ExternalEntity.get({ id: $stateParams.id }).$promise;
                    }], readOnly: function() {
                        return false;
                    }, previousState: ['$state', function($state) {
                        return {
                            name: $state.current.name || 'external-entity',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('external-entity.edit', {
                parent: 'external-entity', url: '/edit', data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                }, views: {
                    'content@': {
                        templateUrl: 'app/entities/external-entity/external-entity-dialog.html',
                        controller: 'ExternalEntityDialogController',
                        controllerAs: 'vm'
                    }
                }, params: {
                    id: null, ceid: null
                }, resolve: {
                    entity: ['$stateParams', 'ExternalEntity', function($stateParams, ExternalEntity) {
                        if ($stateParams.id != null) {
                            return ExternalEntity.get({ id: $stateParams.id }).$promise;
                        }
                        return null;
                    }], readOnly: function() {
                        return false;
                    }, previousState: ['$state', function($state) {
                        return {
                            name: $state.current.name || 'external-entity',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('external-entity.delete', {
                parent: 'external-entity', url: '/{id}/delete', data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                }, onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/external-entity/external-entity-delete-dialog.html',
                        controller: 'ExternalEntityDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ExternalEntity', function(ExternalEntity) {
                                return ExternalEntity.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('external-entity', null, { reload: 'external-entity' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();

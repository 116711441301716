(function () {
    'use strict';
    angular.module('eDocumentsApp').factory('CommunicationParameter', CommunicationParameter);

    CommunicationParameter.$inject = ['$resource'];

    function CommunicationParameter($resource) {
        var resourceUrl = 'api/communication-parameters/:id';

        return $resource(resourceUrl, {}, {
            'query'                         : {
                method : 'GET',
                isArray: true
            },
            'get'                           : {
                method           : 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update'                        : {
                method: 'PUT'
            },
            'getCommunicationTypesForCommunicationEntity'         : {
                method           : 'GET',
                isArray          : false,
                url              : 'api/communication-types-for-communication-entity/:id'
            },
            'getCommunicationTypes'         : {
                method           : 'GET',
                isArray          : false,
                url              : 'api/communication-types'
            },
            'getCommunicationParameterTypes': {
                method           : 'GET',
                isArray          : true,
                url              : 'api/communication-parameter-types',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();

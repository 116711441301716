(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('JhiMetricsService', JhiMetricsService);

    JhiMetricsService.$inject = ['$http'];

    function JhiMetricsService($http) {
        return {
            getMetrics: getMetrics,
            threadDump: threadDump
        };

        function getMetrics() {
            return $http.get('management/metrics').then(function (response) {
                return response.data;
            });
        }

        function threadDump() {
            return $http.get('management/dump').then(function (response) {
                return response.data;
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').factory('DocumentationUtils', DocumentationUtils);

    DocumentationUtils.$inject = ['$http', '$sce', '$translate'];

    function DocumentationUtils($http, $sce, $translate) {
        return {
            downloadUserManual: downloadUserManual,
            downloadAMASAFEUserManual: downloadAMASAFEUserManual
        };

        function downloadUserManual() {
            $http.get('/api/documentation/userManual/downloadPdf', { responseType: 'arraybuffer' }).then(
                function successCallback(response) {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    var fileURL = URL.createObjectURL(blob);
                    window.open($sce.trustAsResourceUrl(fileURL), '_blank');
                    saveAs(blob, 'eDocuments-userManual.pdf', true);
                },
                function errorCallback() {
                    Notification.error({ message: $translate.instant('error.errorGeneratingFile'), delay: '' });
                }
            );
        }

        function downloadAMASAFEUserManual() {
            $http.get('/api/documentation/amaSafeUserManual/downloadPdf', { responseType: 'arraybuffer' }).then(
                function successCallback(response) {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    var fileURL = URL.createObjectURL(blob);
                    window.open($sce.trustAsResourceUrl(fileURL), '_blank');
                    saveAs(blob, 'amaSafeCredential_userManual.pdf', true);
                },
                function errorCallback() {
                    Notification.error({ message: $translate.instant('error.errorGeneratingFile'), delay: '' });
                }
            );
        }
    }
})();

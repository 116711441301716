(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('admin-documents', {
            parent: 'admin',
            url: '/admin-documents?page&sort',
            data: {
                authorities: ['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
                pageTitle: 'document.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/admin-documents/admin-documents.html',
                    controller: 'AdminDocumentsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: [
                    '$stateParams',
                    'PaginationUtil',
                    function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }
                ],
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('documentType');
                        $translatePartialLoader.addPart('documentStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }
                ]
            }
        });
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationWorkflowStepController', CommunicationWorkflowStepController);

    CommunicationWorkflowStepController.$inject = ['workflows', 'communicationDefinition'];

    function CommunicationWorkflowStepController(workflows, communicationDefinition) {
        var vm                        = this;
        vm.communicationDefinition    = communicationDefinition;
        vm.communicationWorkflowSteps = workflows;

    }
})();

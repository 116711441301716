(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('user-management', {
                parent: 'admin',
                url: '/user-management?page&sort',
                data: {
                    authorities: ['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
                    pageTitle: 'userManagement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/user-management/user-management.html',
                        controller: 'UserManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: [
                        '$stateParams',
                        'PaginationUtil',
                        function($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }
                    ],
                    translatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('user-management');
                            return $translate.refresh();
                        }
                    ]
                }
            })
            .state('user-management.edit', {
                url: '/{login}/edit',
                data: {
                    authorities: ['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/user-management/user-management-dialog.html',
                        controller: 'UserManagementDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [
                        '$stateParams',
                        'User',
                        function($stateParams, User) {
                            return User.get({ login: $stateParams.login }).$promise;
                        }
                    ],
                    readOnly: function() {
                        return false;
                    },
                    previousState: [
                        '$state',
                        function($state) {
                            return {
                                name: $state.current.name || 'user-management',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        }
                    ]
                }
            })

            .state('user-management.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/user-management/user-management-dialog.html',
                        controller: 'UserManagementDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [
                        'User',
                        function(User) {
                            return new User();
                        }
                    ],
                    readOnly: function() {
                        return false;
                    },
                    previousState: [
                        '$state',
                        function($state) {
                            return {
                                name: $state.current.name || 'user-management',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        }
                    ]
                }
            })
            .state('user-management.delete', {
                url: '/{login}/delete',
                data: {
                    authorities: ['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                },
                onEnter: [
                    '$stateParams',
                    '$state',
                    '$uibModal',
                    function($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl: 'app/admin/user-management/user-management-delete-dialog.html',
                                controller: 'UserManagementDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: [
                                        'User',
                                        function(User) {
                                            return User.get({ login: $stateParams.login }).$promise;
                                        }
                                    ]
                                }
                            })
                            .result.then(
                            function() {
                                $state.go('user-management', null, { reload: true });
                            },
                            function() {
                                $state.go('^');
                            }
                        );
                    }
                ]
            });
    }
})();

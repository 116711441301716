(function() {
    'use strict';

    angular
        .module('eDocumentsApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ui.bootstrap',
            'ngPrettyJson',
            'prettyXml',
            'ngFileUpload',
            'ui.router',
            'ui-notification',
            'ngScrollbars',
            'ngFileUpload',
            'moment-picker',
            'angularMoment',
            'angularjs.daterangepicker',
            'uiCropper',
            'dndLists',
            'cp.ngConfirm',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ui.select',
            'ngSanitize',
            'duScroll',
            'color.picker'
        ])
        .run(run)
        .constant('angularMomentConfig', {
            timezone: 'Europe/Lisbon'
        });

    run.$inject = ['stateHandler', 'translationHandler','amMoment'];

    function run(stateHandler, translationHandler, amMoment) {
        stateHandler.initialize();
        translationHandler.initialize();
        amMoment.changeLocale('pt');
        moment.tz.setDefault('Europe/Lisbon' );
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('CommunicationWorkflowStepDialogController', CommunicationWorkflowStepDialogController);

    CommunicationWorkflowStepDialogController.$inject = [
        'communicationDefinition',
        '$state',
        'readOnly',
        'previousState',
        '$timeout',
        '$scope',
        '$uibModal',
        'entity',
        'CommunicationWorkflowStep',
        'CommunicationDefinition',
        'CommunicationActionDefinition',
        'CommunicationParameter'
    ];

    function CommunicationWorkflowStepDialogController(
        communicationDefinition,
        $state,
        readOnly,
        previousState,
        $timeout,
        $scope,
        $uibModal,
        entity,
        CommunicationWorkflowStep,
        CommunicationDefinition,
        CommunicationActionDefinition,
        CommunicationParameter
    ) {
        var vm = this;
        vm.communicationWorkflowStep = entity;
        vm.communicationDefinition = communicationDefinition;
        if (!vm.communicationWorkflowStep.communicationDefinitionId) {
            vm.communicationWorkflowStep.communicationDefinitionId = vm.communicationDefinition.id;
        }
        vm.selectOriginCommunicationType = undefined;
        vm.selectOriginCommunicationParameterType = undefined;
        vm.selectDestinationCommunicationType = undefined;
        vm.selectDestinationCommunicationParameterType = undefined;
        vm.readOnly = readOnly;
        vm.previousState = previousState;
        vm.clear = clear;
        vm.save = save;
        vm.communicationactiondefinitions = CommunicationActionDefinition.queryAllOrdered();
        vm.addOriginParameter = addOriginParameter;
        vm.addDestinationParameter = addDestinationParameter;
        vm.deleteCommunicationDestinationParameter = deleteCommunicationDestinationParameter;
        vm.deleteCommunicationOriginParameter = deleteCommunicationOriginParameter;
        vm.changeOriginParameter = changeOriginParameter;
        vm.changeDestinationParameter = changeDestinationParameter;
        vm.viewEntitiesParameters = viewEntitiesParameters;
        vm.changeExecutionType = changeExecutionType;
        vm.communicationTypes = CommunicationParameter.getCommunicationTypes();

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go(previousState.name, previousState.params);
        }

        function checkTimeParams(communicationWorkflowStep) {
            if (communicationWorkflowStep.originParameters) {
                communicationWorkflowStep.originParameters.forEach(function(parameter) {
                    if (
                        parameter.communicationParameterType === 'EMAIL_EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_START_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_END_TIME'
                    ) {
                        if (parameter.disableExecutionTime) {
                            parameter.value = -1;
                        }
                    }
                });
            }
            if (communicationWorkflowStep.destinationParameters) {
                communicationWorkflowStep.destinationParameters.forEach(function(parameter) {
                    if (
                        parameter.communicationParameterType === 'EMAIL_EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_START_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_END_TIME'
                    ) {
                        if (parameter.disableExecutionTime) {
                            parameter.value = -1;
                        }
                    }
                });
            }
        }

        function save() {
            vm.isSaving = true;
            checkTimeParams(vm.communicationWorkflowStep);
            if (angular.isDefined(vm.communicationWorkflowStep.id) && vm.communicationWorkflowStep.id !== null) {
                CommunicationWorkflowStep.update(vm.communicationWorkflowStep, onSaveSuccess, onSaveError);
            } else {
                CommunicationWorkflowStep.save(vm.communicationWorkflowStep, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:communicationWorkflowStepUpdate', result);
            $state.go(previousState.name, previousState.params, {
                reload: true
            });
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function changeOriginParameter() {
            vm.communicationParameterTypes = vm.communicationTypes[vm.selectOriginCommunicationType];
        }

        function changeDestinationParameter() {
            vm.communicationParameterTypes = vm.communicationTypes[vm.selectDestinationCommunicationType];
        }

        function deleteCommunicationOriginParameter(communicationParameter) {
            for (var i = 0, len = vm.communicationWorkflowStep.originParameters.length; i < len; i++) {
                if (vm.communicationWorkflowStep.originParameters[i] === communicationParameter) {
                    vm.communicationWorkflowStep.originParameters.splice(i, 1);
                }
            }
        }

        function deleteCommunicationDestinationParameter(communicationParameter) {
            for (var i = 0, len = vm.communicationWorkflowStep.destinationParameters.length; i < len; i++) {
                if (vm.communicationWorkflowStep.destinationParameters[i] === communicationParameter) {
                    vm.communicationWorkflowStep.destinationParameters.splice(i, 1);
                }
            }
        }

        function addOriginParameter() {
            if (vm.selectOriginCommunicationType && vm.selectOriginCommunicationParameterType) {
                var communicationParameter = {
                    communicationType: vm.selectOriginCommunicationType,
                    communicationParameterType: vm.selectOriginCommunicationParameterType,
                    value: ''
                };
                if (!isCommunicationParameterInCommunicationWorkflowStepOriginParametersArray(communicationParameter)) {
                    vm.communicationWorkflowStep.originParameters.push(communicationParameter);
                }
            }
        }

        function addDestinationParameter() {
            if (vm.selectDestinationCommunicationType && vm.selectDestinationCommunicationParameterType) {
                var communicationParameter = {
                    communicationType: vm.selectDestinationCommunicationType,
                    communicationParameterType: vm.selectDestinationCommunicationParameterType,
                    value: ''
                };
                if (!isCommunicationParameterInCommunicationWorkflowStepDestinationParametersArray(communicationParameter)) {
                    vm.communicationWorkflowStep.destinationParameters.push(communicationParameter);
                }
            }
        }

        function isCommunicationParameterInCommunicationWorkflowStepOriginParametersArray(communicationParameter) {
            if (angular.isUndefined(vm.communicationWorkflowStep.originParameters)) {
                vm.communicationWorkflowStep.originParameters = [];
            }
            for (var i = 0, len = vm.communicationWorkflowStep.originParameters.length; i < len; i++) {
                if (
                    vm.communicationWorkflowStep.originParameters[i].communicationType === communicationParameter.communicationType &&
                    vm.communicationWorkflowStep.originParameters[i].communicationParameterType === communicationParameter.communicationParameterType
                ) {
                    return true;
                }
            }
            return false;
        }

        function isCommunicationParameterInCommunicationWorkflowStepDestinationParametersArray(communicationParameter) {
            if (angular.isUndefined(vm.communicationWorkflowStep.destinationParameters)) {
                vm.communicationWorkflowStep.destinationParameters = [];
            }
            for (var i = 0, len = vm.communicationWorkflowStep.destinationParameters.length; i < len; i++) {
                if (
                    vm.communicationWorkflowStep.destinationParameters[i].communicationType === communicationParameter.communicationType &&
                    vm.communicationWorkflowStep.destinationParameters[i].communicationParameterType === communicationParameter.communicationParameterType
                ) {
                    return true;
                }
            }
            return false;
        }

        function viewEntitiesParameters() {
            var temp = CommunicationDefinition.get({ id: vm.communicationWorkflowStep.communicationDefinitionId }).$promise;
            $uibModal.open({
                templateUrl: 'app/entities/communication-parameter/view-parameters-details-dialog.html',
                controller: 'ViewParametersDetailsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    communicationEntityOrigin: undefined, // CommunicationEntity.get({id: vm.communicationDefinition.originId}).$promise,
                    communicationEntityDestination: undefined, // CommunicationEntity.get({id: vm.communicationDefinition.destinationId}).$promise,
                    communicationDefinition: temp
                }
            });
        }

        function changeExecutionType(value) {
            vm.communicationWorkflowStep.executionType = value;
        }
    }
})();

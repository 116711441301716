(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('CommunicationActionDefinitionSearch', CommunicationActionDefinitionSearch);

    CommunicationActionDefinitionSearch.$inject = ['$resource'];

    function CommunicationActionDefinitionSearch($resource) {
        var resourceUrl =  'api/_search/communication-action-definitions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

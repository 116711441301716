(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationActionDefinitionDialogController', CommunicationActionDefinitionDialogController);

    CommunicationActionDefinitionDialogController.$inject = ['$state', 'readOnly', '$timeout', '$scope', 'entity', 'CommunicationActionDefinition', 'CommunicationParameter'];

    function CommunicationActionDefinitionDialogController($state, readOnly, $timeout, $scope, entity, CommunicationActionDefinition, CommunicationParameter) {
        var vm                           = this;
        vm.communicationActionDefinition = entity;
        vm.readOnly                      = readOnly;
        vm.clear                         = clear;
        vm.save                          = save;

        activate();

        function activate() {
            var ids = [];
            if (vm.communicationActionDefinition.requiredParameters && vm.communicationActionDefinition.requiredParameters.length > 0) {
                for (var j = 0; j < vm.communicationActionDefinition.requiredParameters.length; j++) {
                    ids.push(vm.communicationActionDefinition.requiredParameters[j]);
                }
            } else {
                vm.communicationActionDefinition.requiredParameters = [];
            }
            vm.communicationTypes = CommunicationParameter.getCommunicationTypes();
            CommunicationParameter.getCommunicationParameterTypes().$promise.then(function (res) {
                vm.communicationParameterTypes = [];
                for (var i = 0; i < res.length; i++) {
                    if (!ids.includes(res[i])) {
                        vm.communicationParameterTypes.push(res[i]);
                    }
                }
            });
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('communication-action-definition');
        }

        function save() {
            vm.isSaving = true;
            if (angular.isDefined( vm.communicationActionDefinition.id) && vm.communicationActionDefinition.id !== null) {
                CommunicationActionDefinition.update(vm.communicationActionDefinition, onSaveSuccess, onSaveError);
            } else {
                CommunicationActionDefinition.save(vm.communicationActionDefinition, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:communicationActionDefinitionUpdate', result);
            $state.go('communication-action-definition');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('communication-workflow-step', {
                parent: 'app',
                url: '/communication-workflow-step/{sid}',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
                    pageTitle: 'eDocumentsApp.communicationWorkflowStep.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/communication-workflow-step/communication-workflow-steps.html',
                        controller: 'CommunicationWorkflowStepController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    workflows: [
                        '$stateParams',
                        'CommunicationWorkflowStep',
                        function($stateParams, CommunicationWorkflowStep) {
                            return CommunicationWorkflowStep.findByDefinition({ id: $stateParams.sid }).$promise;
                        }
                    ],
                    communicationDefinition: [
                        '$stateParams',
                        'CommunicationDefinition',
                        function($stateParams, CommunicationDefinition) {
                            return CommunicationDefinition.get({ id: $stateParams.sid }).$promise;
                        }
                    ],
                    translatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('communicationWorkflowStep');
                            $translatePartialLoader.addPart('communicationType');
                            $translatePartialLoader.addPart('communicationDefinition');
                            $translatePartialLoader.addPart('communicationParameter');
                            $translatePartialLoader.addPart('communicationParameterType');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }
                    ]
                }
            })
            .state('communication-workflow-step-detail', {
                parent: 'communication-workflow-step',
                url: '/communication-workflow-step/{id}',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
                    pageTitle: 'eDocumentsApp.communicationWorkflowStep.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/communication-workflow-step/communication-workflow-step-dialog.html',
                        controller: 'CommunicationWorkflowStepDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('communicationWorkflowStep');
                            return $translate.refresh();
                        }
                    ],
                    entity: [
                        '$stateParams',
                        'CommunicationWorkflowStep',
                        function($stateParams, CommunicationWorkflowStep) {
                            return CommunicationWorkflowStep.get({ id: $stateParams.id }).$promise;
                        }
                    ],
                    readOnly: function() {
                        return true;
                    },
                    previousState: [
                        '$state',
                        function($state) {
                            return {
                                name: $state.current.name || 'communication-workflow-step',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        }
                    ]
                }
            })
            .state('communication-workflow-step-detail.edit', {
                parent: 'communication-workflow-step-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/communication-workflow-step/communication-workflow-step-dialog.html',
                        controller: 'CommunicationWorkflowStepDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [
                        '$stateParams',
                        'CommunicationWorkflowStep',
                        function($stateParams, CommunicationWorkflowStep) {
                            return CommunicationWorkflowStep.get({ id: $stateParams.id }).$promise;
                        }
                    ],
                    readOnly: function() {
                        return false;
                    },
                    previousState: [
                        '$state',
                        function($state) {
                            return {
                                name: $state.current.name || 'workflow-step',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        }
                    ]
                }
            })
            .state('communication-workflow-step.new', {
                parent: 'communication-workflow-step',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/communication-workflow-step/communication-workflow-step-dialog.html',
                        controller: 'CommunicationWorkflowStepDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [
                        'CommunicationWorkflowStep',
                        function(CommunicationWorkflowStep) {
                            return new CommunicationWorkflowStep();
                        }
                    ],
                    readOnly: function() {
                        return false;
                    },
                    previousState: [
                        '$state',
                        function($state) {
                            return {
                                name: $state.current.name || 'communication-workflow-step',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        }
                    ]
                }
            })
            .state('communication-workflow-step.edit', {
                parent: 'communication-workflow-step',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/communication-workflow-step/communication-workflow-step-dialog.html',
                        controller: 'CommunicationWorkflowStepDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [
                        '$stateParams',
                        'CommunicationWorkflowStep',
                        function($stateParams, CommunicationWorkflowStep) {
                            return CommunicationWorkflowStep.get({ id: $stateParams.id }).$promise;
                        }
                    ],
                    readOnly: function() {
                        return false;
                    },
                    previousState: [
                        '$state',
                        function($state) {
                            return {
                                name: $state.current.name || 'communication-workflow-step',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                        }
                    ]
                }
            })
            .state('communication-workflow-step.delete', {
                parent: 'communication-workflow-step',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']
                },
                onEnter: [
                    '$stateParams',
                    '$state',
                    '$uibModal',
                    function($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl: 'app/entities/communication-workflow-step/communication-workflow-step-delete-dialog.html',
                                controller: 'CommunicationWorkflowStepDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: [
                                        'CommunicationWorkflowStep',
                                        function(CommunicationWorkflowStep) {
                                            return CommunicationWorkflowStep.get({ id: $stateParams.id }).$promise;
                                        }
                                    ]
                                }
                            })
                            .result.then(
                            function() {
                                $state.go('communication-workflow-step', null, { reload: 'communication-workflow-step' });
                            },
                            function() {
                                $state.go('^');
                            }
                        );
                    }
                ]
            });
    }
})();

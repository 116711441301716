(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ContactUsController', ContactUsController);

    ContactUsController.$inject = ['$uibModalInstance', '$translate', 'Account', 'Notification'];

    function ContactUsController($uibModalInstance, $translate, Account, Notification) {
        var vm = this;

        vm.save = save;
        vm.cancel = cancel;
        vm.isSaving = false;
        vm.name = null;
        vm.email = null;
        vm.phone = null;
        vm.company = null;
        vm.subject = null;
        vm.message = null;
        vm.acceptPrivacyPolicy = false;
        vm.acceptMarketingDataUsage = false;
        vm.receiveEmailCommunication = false;

        function save() {
            vm.isSaving = true;
            Account.contactUs({
                name: vm.name,
                email: vm.email,
                phone: vm.phone,
                company: vm.company,
                subject: vm.subject,
                message: vm.message,
                acceptPrivacyPolicy: vm.acceptPrivacyPolicy,
                acceptMarketingDataUsage: vm.acceptMarketingDataUsage,
                receiveEmailCommunication: vm.receiveEmailCommunication
            }, function() {
                vm.isSaving = false;
                Notification.success({ message: $translate.instant('global.knowMore.success.message'), delay: 5000 });
                vm.cancel();
            }, function() {
                Notification.error({ message: $translate.instant('global.knowMore.error.message'), delay: 5000 });
                vm.isSaving = false;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

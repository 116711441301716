(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .directive('characteresValidation', function() {
            var myregex = '^[_\'.A-Za-z0-9-]*$';

            function link(scope, elem, attrs, ngModel) {
                myregex = angular.isDefined(attrs.myregex) ? attrs.myregex : myregex;
                ngModel.$parsers.push(function(viewValue) {
                    // var reg = /^[^`~!@#$%\^&*()+={}|[\]\\:';"<>?,/]*$/;
                    var reg = new RegExp(myregex);
                    // if view values matches regexp, update model value
                    if (viewValue.match(reg)) {
                        return viewValue;
                    }
                    // keep the model value as it is
                    var transformedValue = ngModel.$modelValue;
                    ngModel.$setViewValue(transformedValue);
                    ngModel.$render();
                    return transformedValue;
                });
            }

            return {
                restrict: 'A',
                require: 'ngModel',
                link: link
            };
        });
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('DocumentsExternalController', DocumentsExternalController);

    DocumentsExternalController.$inject = ['$stateParams', 'Document', 'DocumentDownloadPdf'];

    function DocumentsExternalController($stateParams, Document, DocumentDownloadPdf) {
        var vm = this;

        vm.hashMissing = angular.isUndefined($stateParams.hash);
        vm.error = null;
        vm.download = download;
        vm.downloadAllFiles = downloadAllFiles;
        vm.success = null;
        vm.externalDocuments = null;

        Document.getAllByExternalHash({
            hash: $stateParams.hash
        }, function (result) {
            vm.externalDocuments = result;
        }, function (error) {
            vm.externalDocuments = null;
            vm.error = error;
        });

        function download($event, document, fileHashedId) {
            DocumentDownloadPdf.downloadExternal($event, document, fileHashedId);
        }

        function downloadAllFiles($event) {
            DocumentDownloadPdf.downloadAllFilesExternal($event, $stateParams.hash, vm.externalDocuments);
        }
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('send-grid', {
                url: '/unsubscribe?email',
                data: {
                    authorities: []
                },
                views: {
                    'main@': {
                        templateUrl: 'app/layouts/send-grid/send-grid.html',
                        controller: 'SendGridController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('sendGrid');
                            return $translate.refresh();
                        }
                    ]
                }
            })
            .state('send-grid-checker', {
                parent: 'app',
                url: '/sendGrid',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/layouts/send-grid/send-grid-checker.html',
                        controller: 'SendGridCheckerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        '$translate',
                        '$translatePartialLoader',
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('sendGrid');
                            return $translate.refresh();
                        }
                    ]
                }
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ExternalEntitiesInactivateConfirmationController', ExternalEntitiesInactivateConfirmationController);

    ExternalEntitiesInactivateConfirmationController.$inject = ['$uibModalInstance'];

    function ExternalEntitiesInactivateConfirmationController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.confirmInactivation = confirmInactivation;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmInactivation() {
            $uibModalInstance.close(true);
        }
    }
})();

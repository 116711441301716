(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationParameterDialogController', CommunicationParameterDialogController);

    CommunicationParameterDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'CommunicationParameter'];

    function CommunicationParameterDialogController($timeout, $scope, $uibModalInstance, entity, CommunicationParameter) {
        var vm = this;

        vm.communicationParameter = entity;
        vm.clear                  = clear;
        vm.save                   = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (angular.isDefined(vm.communicationParameter.id) && vm.communicationParameter.id !== null) {
                CommunicationParameter.update(vm.communicationParameter, onSaveSuccess, onSaveError);
            } else {
                CommunicationParameter.save(vm.communicationParameter, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:communicationParameterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();

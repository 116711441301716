(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$translate', 'LANGUAGES'];

    function JhiLanguageService($q, $translate, LANGUAGES) {
        return {
            getAll: getAll,
            getCurrent: getCurrent,
            getCurrentLanguageIcon: getCurrentLanguageIcon,
            findLanguageIcon: findLanguageIcon
        };

        function getAll() {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
        }

        function getCurrent() {
            var deferred = $q.defer();
            var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

            deferred.resolve(language);

            return deferred.promise;
        }

        function getCurrentLanguageIcon() {
            return findLanguageIcon($translate.use());
        }

        function findLanguageIcon(languageKey) {
            var code = languageKey.substr(0, 2).toLowerCase();
            if (code === 'en') {
                return 'gb';
            }
            return code;
        }
    }
})();

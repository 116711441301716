(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationDefinitionDialogController', CommunicationDefinitionDialogController);

    CommunicationDefinitionDialogController.$inject = ['$translate', '$state', 'readOnly', '$timeout', '$scope', '$uibModal', '$log', 'entity', 'CommunicationDefinition', 'CommunicationEntity', 'CommunicationParameter', 'Notification'];

    function CommunicationDefinitionDialogController($translate, $state, readOnly, $timeout, $scope, $uibModal, $log, entity, CommunicationDefinition, CommunicationEntity, CommunicationParameter, Notification) {
        var vm = this;
        vm.communicationDefinition = entity;
        vm.readOnly = readOnly;
        vm.selectOriginCommunicationType = undefined;
        vm.selectOriginCommunicationParameterType = undefined;
        vm.selectDestinationCommunicationType = undefined;
        vm.selectDestinationCommunicationParameterType = undefined;
        vm.clear = clear;
        vm.save = save;
        vm.addOriginParameter = addOriginParameter;
        vm.addDestinationParameter = addDestinationParameter;
        vm.deleteCommunicationDestinationParameter = deleteCommunicationDestinationParameter;
        vm.deleteCommunicationOriginParameter = deleteCommunicationOriginParameter;
        vm.changeOriginParameter = changeOriginParameter;
        vm.changeDestinationParameter = changeDestinationParameter;
        vm.viewEntitiesParameters = viewEntitiesParameters;
        vm.communicationTypes = CommunicationParameter.getCommunicationTypes();

        activate();

        function activate() {
            if (!vm.communicationDefinition) {
                clear();
            } else {
                vm.communicationentitiesForOrigin = [{
                    id: vm.communicationDefinition.originId,
                    name: vm.communicationDefinition.originName
                }];

                vm.communicationentitiesForDestination = [{
                    id: vm.communicationDefinition.destinationId,
                    name: vm.communicationDefinition.destinationName
                }];
            }
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('communication-definition');
        }

        function checkTimeParams(communicationDefinition) {
            if (communicationDefinition.originParameters) {
                communicationDefinition.originParameters.forEach(function(parameter) {
                    if (parameter.communicationParameterType === 'EMAIL_EXECUTION_TIME' || parameter.communicationParameterType === 'EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_START_TIME' || parameter.communicationParameterType === 'SUSPENSION_END_TIME') {
                        if (parameter.disableExecutionTime) {
                            parameter.value = -1;
                        }
                    }
                });
            }
            if (communicationDefinition.destinationParameters) {
                communicationDefinition.destinationParameters.forEach(function(parameter) {
                    if (parameter.communicationParameterType === 'EMAIL_EXECUTION_TIME' || parameter.communicationParameterType === 'EXECUTION_TIME' ||
                        parameter.communicationParameterType === 'SUSPENSION_START_TIME' || parameter.communicationParameterType === 'SUSPENSION_END_TIME') {
                        if (parameter.disableExecutionTime) {
                            parameter.value = -1;
                        }
                    }
                });
            }
        }

        function save() {
            vm.isSaving = true;
            checkTimeParams(vm.communicationDefinition);
            if (angular.isDefined(vm.communicationDefinition.id) && vm.communicationDefinition.id !== null) {
                CommunicationDefinition.update(vm.communicationDefinition, onSaveSuccess, onSaveError);
            } else {
                CommunicationDefinition.save(vm.communicationDefinition, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            if (!result.body) {
                Notification.error({ message: $translate.instant('eDocumentsApp.communicationDefinition.errors.communicationDefinitionBetweenEntitiesAlreadyExists') });
            }
            $scope.$emit('eDocumentsApp:communicationDefinitionUpdate', result);
            $state.go('communication-definition');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function changeOriginParameter() {
            vm.communicationParameterTypes = vm.communicationTypes[vm.selectOriginCommunicationType];
        }

        function changeDestinationParameter() {
            vm.communicationParameterTypes = vm.communicationTypes[vm.selectDestinationCommunicationType];
        }

        function deleteCommunicationOriginParameter(communicationParameter) {
            for (var i = 0, len = vm.communicationDefinition.originParameters.length; i < len; i++) {
                if (vm.communicationDefinition.originParameters[i] === communicationParameter) {
                    vm.communicationDefinition.originParameters.splice(i, 1);
                }
            }
        }

        function deleteCommunicationDestinationParameter(communicationParameter) {
            for (var i = 0, len = vm.communicationDefinition.destinationParameters.length; i < len; i++) {
                if (vm.communicationDefinition.destinationParameters[i] === communicationParameter) {
                    vm.communicationDefinition.destinationParameters.splice(i, 1);
                }
            }
        }

        function addOriginParameter() {
            if (vm.selectOriginCommunicationType && vm.selectOriginCommunicationParameterType) {
                var communicationParameter = {
                    communicationType: vm.selectOriginCommunicationType,
                    communicationParameterType: vm.selectOriginCommunicationParameterType,
                    value: ''
                };
                if (!isCommunicationParameterInCommunicationEntityOriginParametersArray(communicationParameter)) {
                    vm.communicationDefinition.originParameters.push(communicationParameter);
                }
            }
        }

        function addDestinationParameter() {
            if (vm.selectDestinationCommunicationType && vm.selectDestinationCommunicationParameterType) {
                var communicationParameter = {
                    communicationType: vm.selectDestinationCommunicationType,
                    communicationParameterType: vm.selectDestinationCommunicationParameterType,
                    value: ''
                };
                if (!isCommunicationParameterInCommunicationEntityDestinationParametersArray(communicationParameter)) {
                    vm.communicationDefinition.destinationParameters.push(communicationParameter);
                }
            }
        }

        function isCommunicationParameterInCommunicationEntityOriginParametersArray(communicationParameter) {
            if (angular.isUndefined(vm.communicationDefinition.originParameters)) {
                vm.communicationDefinition.originParameters = [];
            }
            for (var i = 0, len = vm.communicationDefinition.originParameters.length; i < len; i++) {
                if (vm.communicationDefinition.originParameters[i].communicationType === communicationParameter.communicationType && vm.communicationDefinition.originParameters[i].communicationParameterType === communicationParameter.communicationParameterType) {
                    return true;
                }
            }
            return false;
        }

        function isCommunicationParameterInCommunicationEntityDestinationParametersArray(communicationParameter) {
            if (angular.isUndefined(vm.communicationDefinition.destinationParameters)) {
                vm.communicationDefinition.destinationParameters = [];
            }
            for (var i = 0, len = vm.communicationDefinition.destinationParameters.length; i < len; i++) {
                if (vm.communicationDefinition.destinationParameters[i].communicationType === communicationParameter.communicationType && vm.communicationDefinition.destinationParameters[i].communicationParameterType === communicationParameter.communicationParameterType) {
                    return true;
                }
            }
            return false;
        }

        $scope.refreshSelectModelForOrigin = function(query) {
            if (vm.lastQuery !== query) {
                vm.lastQuery = query;
                CommunicationEntity.queryForSelect({ search: query }, onSearchOriginSuccess, onSearchOriginError);
            }
        };

        function onSearchOriginSuccess(result) {
            if (result.length > 50) {
                Notification.warning({
                    message: 'O número de resultados é superior a 500 registos. Por questões de performance, refine a sua pesquisa',
                    delay: 5000
                });
            } else {
                vm.communicationentitiesForOrigin = result;
            }
        }

        function onSearchOriginError(e) {
            $log.error(e);
        }

        $scope.refreshSelectModelForDestination = function(query) {
            if (vm.lastQuery !== query) {
                vm.lastQuery = query;
                CommunicationEntity.queryForSelect({ search: query }, onSearchDestinationSuccess, onSearchDestinationError);
            }
        };

        function onSearchDestinationSuccess(result) {
            if (result.length > 50) {
                Notification.warning({
                    message: 'O número de resultados é superior a 500 registos. Por questões de performance, refine a sua pesquisa',
                    delay: 5000
                });
            } else {
                vm.communicationentitiesForDestination = result;
            }
        }

        function onSearchDestinationError(e) {
            $log.error(e);
        }

        function viewEntitiesParameters() {
            $uibModal.open({
                templateUrl: 'app/entities/communication-parameter/view-parameters-details-dialog.html',
                controller: 'ViewParametersDetailsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    communicationEntityOrigin: CommunicationEntity.get({ id: vm.communicationDefinition.originId }).$promise,
                    communicationEntityDestination: CommunicationEntity.get({ id: vm.communicationDefinition.destinationId }).$promise,
                    communicationDefinition: undefined
                }

            });
        }

    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('FooterController', FooterController);

    FooterController.$inject = ['$uibModal'];

    function FooterController($uibModal) {
        var vm = this;

        vm.knowMore = knowMore;

        function knowMore() {
            $uibModal.open({
                templateUrl: 'app/layouts/footer/know-more/know-more-dialog.html',
                controller: 'KnowMoreController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md'
            }).result.then(function() {

            }, function() {
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(alertServiceConfig);

    alertServiceConfig.$inject = ['AlertServiceProvider', 'NotificationProvider'];

    function alertServiceConfig(AlertServiceProvider, NotificationProvider) {
        // set below to true to make alerts look like toast
        AlertServiceProvider.showAsToast(false);
        NotificationProvider.setOptions({
            delay            : 10000,
            startTop         : 20,
            startRight       : 10,
            verticalSpacing  : 20,
            horizontalSpacing: 20,
            positionX        : 'right',
            positionY        : 'top'
        });
    }
})();

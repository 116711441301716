(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('InvoiceLineDetailController', InvoiceLineDetailController);

    InvoiceLineDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'entity'];

    function InvoiceLineDetailController($scope, $rootScope, previousState, entity) {
        var vm = this;

        vm.invoiceLine   = entity;
        vm.previousState = previousState.name;
        // eslint-disable-next-line
        var unsubscribe = $rootScope.$on('eDocumentsApp:invoiceLineUpdate', function (event, result) {
            vm.invoiceLine = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

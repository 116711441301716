(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('invoice-header', {
                parent : 'entity',
                url    : '/invoice-header?page&sort&search',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.invoiceHeader.home.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/invoice-header/invoice-headers.html',
                        controller  : 'InvoiceHeaderController',
                        controllerAs: 'vm'
                    }
                },
                params : {
                    page  : {
                        value : '1',
                        squash: true
                    },
                    sort  : {
                        value : 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams          : ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page     : PaginationUtil.parsePage($stateParams.page),
                            sort     : $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search   : $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('invoiceHeader');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invoice-header-detail', {
                parent : 'invoice-header',
                url    : '/invoice-header/{id}',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.invoiceHeader.detail.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/invoice-header/invoice-header-detail.html',
                        controller  : 'InvoiceHeaderDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('invoiceHeader');
                        return $translate.refresh();
                    }],
                    entity                : ['$stateParams', 'InvoiceHeader', function ($stateParams, InvoiceHeader) {
                        return InvoiceHeader.get({id: $stateParams.id}).$promise;
                    }],
                    previousState         : ["$state", function ($state) {
                        return {
                            name  : $state.current.name || 'invoice-header',
                            params: $state.params,
                            url   : $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('invoice-header-detail.edit', {
                parent : 'invoice-header-detail',
                url    : '/detail/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/invoice-header/invoice-header-dialog.html',
                        controller  : 'InvoiceHeaderDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['InvoiceHeader', function (InvoiceHeader) {
                                return InvoiceHeader.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('invoice-header.new', {
                parent : 'invoice-header',
                url    : '/new',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$state', '$uibModal', function ($state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/invoice-header/invoice-header-dialog.html',
                        controller  : 'InvoiceHeaderDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: function () {
                                return {
                                    environment           : null,
                                    company               : null,
                                    subCompany            : null,
                                    documentType          : null,
                                    selfBilling           : null,
                                    documentSeries        : null,
                                    documentNumber        : null,
                                    supplierCode          : null,
                                    documentCertification : null,
                                    salesOrderNumber      : null,
                                    salesOrderDate        : null,
                                    invoiceExpirationDate : null,
                                    customerOrderReference: null, // should be PurchaseOrderNumber
                                    externalCustomerId    : null,
                                    currencyCode          : null,
                                    vatBaseAmount         : null,
                                    vatAmount             : null,
                                    totalAmount           : null,
                                    totalDiscountAmount   : null,
                                    dueDate               : null,
                                    salesReference        : null,
                                    id                    : null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('invoice-header', null, {reload: 'invoice-header'});
                    }, function () {
                        $state.go('invoice-header');
                    });
                }]
            })
            .state('invoice-header.edit', {
                parent : 'invoice-header',
                url    : '/{id}/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/invoice-header/invoice-header-dialog.html',
                        controller  : 'InvoiceHeaderDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['InvoiceHeader', function (InvoiceHeader) {
                                return InvoiceHeader.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('invoice-header', null, {reload: 'invoice-header'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('invoice-header.delete', {
                parent : 'invoice-header',
                url    : '/{id}/delete',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/invoice-header/invoice-header-delete-dialog.html',
                        controller  : 'InvoiceHeaderDeleteController',
                        controllerAs: 'vm',
                        size        : 'md',
                        resolve     : {
                            entity: ['InvoiceHeader', function (InvoiceHeader) {
                                return InvoiceHeader.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('invoice-header', null, {reload: 'invoice-header'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('DocumentApprovalByHashIdController', DocumentApprovalByHashIdController);

    DocumentApprovalByHashIdController.$inject = ['$uibModalInstance', 'entity', 'Document', 'CurrencyUtils'];

    function DocumentApprovalByHashIdController($uibModalInstance, entity, Document, CurrencyUtils) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.confirmApproval = confirmApproval;
        vm.getCurrency = CurrencyUtils.getCurrency;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmApproval(hashedId) {
            Document.approveByHashId({hash: hashedId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

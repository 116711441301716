(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User($resource) {
        return $resource('api/users/:login', {}, {
            'query'             : {
                method : 'GET',
                isArray: true
            },
            'get'               : {
                method           : 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'findByEntity'      : {
                method : 'GET',
                url    : 'api/users-by-entity/:id',
                isArray: true
            },
            'addEntityToUser'   : {
                method : 'PUT',
                url    : 'api/users-add-entity',
                isArray: false
            },
            'updateUserEntity'  : {
                method : 'PUT',
                url    : 'api/users-update-entity',
                isArray: false
            },
            'removeEntityToUser': {
                method : 'GET',
                url    : 'api/users-remove-entity/:userId/:entityId',
                isArray: false
            },
            'findByEmail'       : {
                method : 'GET',
                url    : 'api/users-by-email/:email',
                isArray: false
            },
            'save'              : {method: 'POST'},
            'update'            : {method: 'PUT'},
            'delete'            : {method: 'DELETE'}
        });
    }
})();

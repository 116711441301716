(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('smart-search', {
            parent: 'app',
            url: '/smart-search?page&sort&search',
            data: {
                authorities: ['ROLE_SUPERADMIN', 'ROLE_BETATESTER'],
                pageTitle: 'eDocumentsApp.smart-search.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/smart-search/smart-search.html',
                    controller: 'SmartSearchController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sid: null,
                eid: null,
                did: null,
                oid: null,
                sort: {
                    value: 'createdDate,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: [
                    '$stateParams',
                    'PaginationUtil',
                    function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }
                ],
                loggeduser: [
                    'Principal',
                    function(Principal) {
                        return Principal.identity().$promise;
                    }
                ],
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('smartSearch');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invoiceHeader');
                        $translatePartialLoader.addPart('invoiceLine');
                        $translatePartialLoader.addPart('invoiceType');
                        $translatePartialLoader.addPart('communicationEntity');
                        return $translate.refresh();
                    }
                ]
            }
        });
    }
})();

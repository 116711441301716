(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('AmaSafeUtilService', AmaSafeUtilService);

    AmaSafeUtilService.$inject = ['$resource', 'DataUtils'];

    function AmaSafeUtilService($resource, DataUtils) {
        var resourceUrl = 'api/ama-safe-utils';

        return $resource(resourceUrl, {}, {
            'callAuthTest': {
                method: 'GET',
                url: 'api/ama-safe-utils/callAuthTest',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCredentialID': {
                method: 'GET',
                url: 'api/ama-safe-utils/getCredentialID',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'generateExample': {
                method: 'POST',
                url: 'api/ama-safe-utils/generateExample',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    return {
                        data: data,
                        filename: DataUtils.parseHeaderFilename(headers),
                    };
                }
            }
        });
    }
})();

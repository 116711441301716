(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('ama-safe-credential-new', {
            parent: 'entity',
            url: '/ama-safe-credential/new',
            data: {
                authorities: ['ROLE_SUPERADMIN', 'ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ama-safe-credential/ama-safe-credential-dialog.html',
                    controller: 'AmaSafeCredentialDialogController',
                    controllerAs: 'vm'
                }
            },
            params: {
                communicationEntityId: null
            },
            resolve: {
                entity: [
                    '$stateParams',
                    'CommunicationEntity',
                    function($stateParams, CommunicationEntity) {
                        if ($stateParams.communicationEntityId !== null) {
                            return CommunicationEntity.get({ id: $stateParams.communicationEntityId }).$promise;
                        }
                        return null;
                    }
                ],
                previousState: [
                    '$state',
                    function($state) {
                        return {
                            name: $state.current.name || 'ama-safe-credential',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }
                ],
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('amaSafeCredential');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }
                ]
            }
        });
        // .state('ama-safe-credential.delete', {
        //   parent: 'ama-safe-credential',
        //   url: '/{id}/delete',
        //   data: {
        //     authorities: ['ROLE_SUPERADMIN'],
        //   },
        //   onEnter: [
        //     '$stateParams',
        //     '$state',
        //     '$uibModal',
        //     function ($stateParams, $state, $uibModal) {
        //       $uibModal
        //         .open({
        //           templateUrl: 'app/entities/ama-safe-credential/ama-safe-credential-delete-dialog.html',
        //           controller: 'AmaSafeCredentialDeleteController',
        //           controllerAs: 'vm',
        //           size: 'md',
        //           resolve: {
        //             entity: [
        //               'AmaSafeCredential',
        //               function (AmaSafeCredential) {
        //                 return AmaSafeCredential.get({ id: $stateParams.id }).$promise;
        //               },
        //             ],
        //           },
        //         })
        //         .result.then(
        //           function () {
        //             $state.go('ama-safe-credential', null, { reload: 'ama-safe-credential' });
        //           },
        //           function () {
        //             $state.go('^');
        //           }
        //         );
        //     },
        //   ],
        // });
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationActionDefinitionErrorTranslationDetailController', CommunicationActionDefinitionErrorTranslationDetailController);

    CommunicationActionDefinitionErrorTranslationDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'entity'];

    function CommunicationActionDefinitionErrorTranslationDetailController($scope, $rootScope, previousState, entity) {
        var vm = this;

        vm.communicationActionDefinitionErrorTranslation = entity;
        vm.previousState                                 = previousState.name;
        // eslint-disable-next-line
        var unsubscribe = $rootScope.$on('eDocumentsApp:communicationActionDefinitionErrorTranslationUpdate', function (event, result) {
            vm.communicationActionDefinitionErrorTranslation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

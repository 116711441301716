(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('LoginController', LoginController);

    LoginController.$inject = [
        '$rootScope',
        '$state',
        '$timeout',
        'Auth',
        '$uibModalInstance',
        'LoginService',
        'ContactUsService',
        'DocumentationUtils'
    ];

    function LoginController($rootScope, $state, $timeout, Auth, $uibModalInstance, LoginService, ContactUsService, DocumentationUtils) {
        var vm = this;
        vm.authenticationError = false;
        vm.credentials = {};
        vm.username = null;
        vm.password = null;
        vm.rememberMe = true;
        vm.isSaving = false;
        vm.cancel = cancel;
        vm.login = login;
        vm.contactUs = contactUs;
        vm.downloadManual = downloadManual;
        vm.requestResetPassword = requestResetPassword;

        $timeout(function() {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login(event) {
            event.preventDefault();
            vm.isSaving = true;
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            })
                .then(function() {
                    vm.isSaving = false;
                    vm.authenticationError = false;
                    $uibModalInstance.close();
                    if ($state.current.name === 'activate' || $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('document');
                    }

                    $rootScope.$broadcast('authenticationSuccess');

                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                })
                .catch(function() {
                    vm.isSaving = false;
                    vm.authenticationError = true;
                });
        }

        function contactUs() {
            $uibModalInstance.dismiss('cancel');
            ContactUsService.open();
        }

        function downloadManual() {
            DocumentationUtils.downloadUserManual();
        }

        function requestResetPassword() {
            LoginService.reset();
        }
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('ExternalEntitiesErrorController', ExternalEntitiesErrorController);

    ExternalEntitiesErrorController.$inject = [
        '$uibModalInstance',
        'list'
    ];

    function ExternalEntitiesErrorController(
        $uibModalInstance,
        list
    ) {
        var vm = this;
        vm.externalEntities = list;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('AmaSafeCredentialAddConfirmationDialogController', AmaSafeCredentialAddConfirmationDialogController);

    AmaSafeCredentialAddConfirmationDialogController.$inject = ['$uibModalInstance'];

    function AmaSafeCredentialAddConfirmationDialogController($uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.accept = accept;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function accept() {
            $uibModalInstance.close();
        }
    }
})();

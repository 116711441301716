(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('AmaSafeUtilDialogController', AmaSafeUtilDialogController);

    AmaSafeUtilDialogController.$inject = ['$uibModalInstance', 'responseStatus', 'message'];

    function AmaSafeUtilDialogController($uibModalInstance, responseStatus, message) {
        var vm = this;
        vm.responseStatus = responseStatus;
        vm.message = message;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('document-storage-detail', {
            parent: 'document',
            url: '/document-storage/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER'],
                pageTitle: 'eDocumentsApp.documentStorage.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/document-storage/document-storage-detail.html',
                    controller: 'DocumentStorageDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('documentStorage');
                        return $translate.refresh();
                    }
                ],
                entity: [
                    '$stateParams',
                    'DocumentStorage',
                    function($stateParams, DocumentStorage) {
                        return DocumentStorage.getByDocument({ id: $stateParams.id }).$promise.then(
                            function(result) {
                                return result;
                            },
                            function() {
                                return null;
                            }
                        );
                    }
                ]
            }
        });
    }
})();

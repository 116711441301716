(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ExternalEntityDeleteController',ExternalEntityDeleteController);

    ExternalEntityDeleteController.$inject = ['$uibModalInstance', 'entity', 'ExternalEntity', 'Notification'];

    function ExternalEntityDeleteController($uibModalInstance, entity, ExternalEntity, Notification) {
        var vm = this;

        vm.externalEntity = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ExternalEntity.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                },
                function (error) {
                    Notification.error({ message: error.data.detail });
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$timeout', '$state', '$uibModalInstance', 'Auth', 'LoginService'];

    function ResetFinishController($stateParams, $timeout, $state, $uibModalInstance, Auth, LoginService) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.resetAccount = {};
        vm.success = null;
        vm.login = login;
        vm.cancel = cancel;
        vm.finishReset = finishReset;

        $timeout(function () {
            angular.element('#password').focus();
        });

        function login() {
            $uibModalInstance.dismiss('cancel');
            $state.go('landing-page');
            LoginService.open();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
            $state.go('landing-page');
        }

        function finishReset() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({
                    key: $stateParams.key,
                    newPassword: vm.resetAccount.password
                }).then(function () {
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();

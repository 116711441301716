(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils($filter) {

        return {
            convertDateTimeFromServer : convertDateTimeFromServer,
            convertStringToTime       : convertStringToTime,
            convertTimeToString       : convertTimeToString,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateToServer  : convertLocalDateToServer,
            dateformat                : dateformat
        };

        function convertDateTimeFromServer(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertStringToTime(date) {
            if (date) {
                var time = date.split(':');
                var d    = new Date();
                d.setHours(+time[0]);
                d.setMinutes(time[1]);
                d.setSeconds(0);
                d.setMilliseconds(0);
                return d;
            } else {
                return null;
            }
        }

        function convertTimeToString(date) {
            if (angular.isDefined(date) && angular.isDefined(date.getHours()) && angular.isDefined(date.getMinutes())) {
                return date.getHours() + ":" + pad(date.getMinutes(), 2);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function dateformat() {
            return 'yyyy-MM-dd';
        }

        function pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
    }

})();

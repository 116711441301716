(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('AmaSafeCredentialReasonDialogController', AmaSafeCredentialReasonDialogController);

    AmaSafeCredentialReasonDialogController.$inject = ['$uibModalInstance'];

    function AmaSafeCredentialReasonDialogController($uibModalInstance) {
        var vm = this;
        vm.reasons = [
            'PROOF_OF_ORIGIN',
            'PROOF_OF_APPROVAL',
            'PROOF_OF_CREATION',
            'DATA_AUTHENTICATION',
            'ENTITY_AUTHENTICATION',
            'AUTHORSHIP',
            'REVIEW',
            'COPY',
            'SIGNATURE_WITNESS',
            'BOUND_TO_DATA_SIGNED',
            'INTERMEDIATE_APPROVAL'
        ];

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

(function () {
    'use strict';

    angular.module('eDocumentsApp').controller('AmaSafeCredentialDeleteController', AmaSafeCredentialDeleteController);

    AmaSafeCredentialDeleteController.$inject = ['$uibModalInstance', 'amaSafeCredential'];

    function AmaSafeCredentialDeleteController($uibModalInstance, amaSafeCredential) {
        var vm = this;

        vm.amaSafeCredential = amaSafeCredential;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete() {
            $uibModalInstance.close(true);
        }
    }
})();

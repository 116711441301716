(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account($resource) {
        return $resource('api/account', {}, {
            'get'                    : {
                method     : 'GET',
                params     : {},
                isArray    : false,
                interceptor: {
                    response: function (response) {
                        // expose response
                        return response;
                    }
                }
            },
            'getHomeInfo'            : {
                method: 'GET',
                url   : 'api/homeInfo'
            },
            'createRegistrationCache': {
                method: 'GET',
                url   : 'api/createRegistrationCache'
            },
            'knowMore': {
                method: 'POST',
                url   : 'api/account/know-more'
            },
            'contactUs': {
                method: 'POST',
                url   : 'api/account/contact-us'
            },
            'addToNewsletter': {
                method: 'POST',
                url   : 'api/account/add-to-newsletter'
            }
        });
    }
})();

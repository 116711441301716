(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ExternalEntitiesActivateConfirmationController', ExternalEntitiesActivateConfirmationController);

    ExternalEntitiesActivateConfirmationController.$inject = ['$uibModalInstance'];

    function ExternalEntitiesActivateConfirmationController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.confirmActivation = confirmActivation;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmActivation() {
            $uibModalInstance.close(true);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ExportAllDocumentsErrorController', ExportAllDocumentsErrorController);

    ExportAllDocumentsErrorController.$inject = ['$uibModalInstance', 'numberOfDocuments'];

    function ExportAllDocumentsErrorController($uibModalInstance, numberOfDocuments) {
        var vm = this;

        vm.numberOfDocuments = numberOfDocuments.total;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('InvoiceLineDialogController', InvoiceLineDialogController);

    InvoiceLineDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'InvoiceLine', 'Document'];

    function InvoiceLineDialogController($timeout, $scope,  $uibModalInstance, entity, InvoiceLine, Document) {
        var vm = this;

        vm.invoiceLine          = entity;
        vm.clear                = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar         = openCalendar;
        vm.save                 = save;
        vm.documents            = Document.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (angular.isDefined(vm.invoiceLine.id) && vm.invoiceLine.id !== null) {
                InvoiceLine.update(vm.invoiceLine, onSaveSuccess, onSaveError);
            } else {
                InvoiceLine.save(vm.invoiceLine, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:invoiceLineUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.lotValidUntil = false;
        vm.datePickerOpenStatus.deliveryDate  = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('communication-parameter', {
                parent : 'entity',
                url    : '/communication-parameter',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.communicationParameter.home.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/communication-parameter/communication-parameters.html',
                        controller  : 'CommunicationParameterController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('communicationParameter');
                        $translatePartialLoader.addPart('communicationType');
                        $translatePartialLoader.addPart('communicationParameterType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('communication-parameter-detail', {
                parent : 'communication-parameter',
                url    : '/communication-parameter/{id}',
                data   : {
                    authorities: ['ROLE_USER'],
                    pageTitle  : 'eDocumentsApp.communicationParameter.detail.title'
                },
                views  : {
                    'content@': {
                        templateUrl : 'app/entities/communication-parameter/communication-parameter-detail.html',
                        controller  : 'CommunicationParameterDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('communicationParameter');
                        $translatePartialLoader.addPart('communicationType');
                        $translatePartialLoader.addPart('communicationParameterType');
                        return $translate.refresh();
                    }],
                    entity                : ['$stateParams', 'CommunicationParameter', function ($stateParams, CommunicationParameter) {
                        return CommunicationParameter.get({id: $stateParams.id}).$promise;
                    }],
                    previousState         : ["$state", function ($state) {
                        return {
                            name  : $state.current.name || 'communication-parameter',
                            params: $state.params,
                            url   : $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('communication-parameter-detail.edit', {
                parent : 'communication-parameter-detail',
                url    : '/detail/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication-parameter/communication-parameter-dialog.html',
                        controller  : 'CommunicationParameterDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['CommunicationParameter', function (CommunicationParameter) {
                                return CommunicationParameter.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('communication-parameter.new', {
                parent : 'communication-parameter',
                url    : '/new',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$state', '$uibModal', function ($state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication-parameter/communication-parameter-dialog.html',
                        controller  : 'CommunicationParameterDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: function () {
                                return {
                                    communicationType         : null,
                                    communicationParameterType: null,
                                    value                     : null,
                                    id                        : null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('communication-parameter', null, {reload: 'communication-parameter'});
                    }, function () {
                        $state.go('communication-parameter');
                    });
                }]
            })
            .state('communication-parameter.edit', {
                parent : 'communication-parameter',
                url    : '/{id}/edit',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication-parameter/communication-parameter-dialog.html',
                        controller  : 'CommunicationParameterDialogController',
                        controllerAs: 'vm',
                        backdrop    : 'static',
                        size        : 'lg',
                        resolve     : {
                            entity: ['CommunicationParameter', function (CommunicationParameter) {
                                return CommunicationParameter.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('communication-parameter', null, {reload: 'communication-parameter'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('communication-parameter.delete', {
                parent : 'communication-parameter',
                url    : '/{id}/delete',
                data   : {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl : 'app/entities/communication-parameter/communication-parameter-delete-dialog.html',
                        controller  : 'CommunicationParameterDeleteController',
                        controllerAs: 'vm',
                        size        : 'md',
                        resolve     : {
                            entity: ['CommunicationParameter', function (CommunicationParameter) {
                                return CommunicationParameter.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('communication-parameter', null, {reload: 'communication-parameter'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();

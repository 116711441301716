(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('CommunicationEntityController', CommunicationEntityController);

    CommunicationEntityController.$inject = [
        'Principal',
        '$state',
        'CommunicationEntity',
        'ParseLinks',
        'AlertService',
        'paginationConstants',
        'pagingParams',
        'PaginationUtil'
    ];

    function CommunicationEntityController(
        Principal,
        $state,
        CommunicationEntity,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        PaginationUtil
    ) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.reset = reset;
        vm.loadAll = loadAll;

        vm.totalItems = 1000;

        vm.changeActive = changeActive;
        vm.isSearchActive = isSearchActive;

        function loadAll() {
            vm.filters = PaginationUtil.updateFilters($state.current.name, vm.page - 1, vm.filters.filterBy, vm.predicate, vm.reverse, vm.itemsPerPage);
            Principal.identity().then(function(account) {
                vm.account = account;
                CommunicationEntity.query(
                    {
                        page: vm.filters.currentPage,
                        size: vm.filters.itemsPerPage,
                        search: search(),
                        sort: sort()
                    },
                    onSuccess,
                    onError
                );
            });

            function sort() {
                if (!vm.predicate) {
                    vm.predicate = 'id';
                }
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                angular.forEach(data, function(item) {
                    if (
                        vm.account.authorities.indexOf('ROLE_SUPERADMIN') !== -1 ||
                        vm.account.authorities.indexOf('ROLE_ADMIN') !== -1 ||
                        vm.account.authorities.indexOf('ROLE_PARTNER') !== -1
                    ) {
                        item.manager = true;
                    } else {
                        for (var i = 0; i < item.userEntities.length; i++) {
                            if (item.userEntities[i].entityId === item.id && vm.account.id === item.userEntities[i].userId) {
                                item.manager = item.userEntities[i].manager;
                                break;
                            } else {
                                item.manager = false;
                            }
                        }
                    }
                });
                vm.communicationEntities = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function search() {
            var result = '';
            for (var x in vm.filters.filterBy) {
                if (vm.filters.filterBy[x] && vm.filters.filterBy[x].value) {
                    result += vm.filters.filterBy[x].name + ':' + vm.filters.filterBy[x].value + '#AND,';
                }
            }
            return result;
        }

        function changeActive(value) {
            vm.filters.filterBy.active.value = value;
            vm.page = 1;
            loadAll();
        }

        function isSearchActive() {
            return (
                vm.filters.filterBy.name.value ||
                angular.isDefined(vm.filters.filterBy.active.value) ||
                vm.filters.filterBy.vatRegistrationNumber.value ||
                vm.filters.filterBy.gln.value ||
                vm.filters.filterBy.email.value
            );
        }

        function reset() {
            vm.filters = PaginationUtil.clearFilters($state.current.name, filterBy());
            loadAll();
        }

        function initFilters() {
            vm.filters = PaginationUtil.getFilters($state.current.name, filterBy());

            vm.page = vm.filters.currentPage + 1;
            vm.predicate = vm.filters.orderBy;
            vm.reverse = vm.filters.orderReverse;
            loadAll();
        }

        initFilters();

        function filterBy() {
            return {
                name: {
                    name: 'name',
                    value: undefined
                },
                active: {
                    name: 'active',
                    value: undefined
                },
                vatRegistrationNumber: {
                    name: 'vatRegistrationNumber',
                    value: undefined
                },
                gln: {
                    name: 'gln',
                    value: undefined
                },
                email: {
                    name: 'email',
                    value: undefined
                }
            };
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('PasswordResetInit', PasswordResetInit);

    PasswordResetInit.$inject = ['$resource'];

    function PasswordResetInit($resource) {
        return $resource('api/account/reset-password/init', {}, {});
    }
})();

/* global d3:true, _:true */

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'Account', 'DocumentDownloadPdf', 'CurrencyUtils'];

    function HomeController($scope, Principal, Account, DocumentDownloadPdf, CurrencyUtils) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.activeView = 'ORIGIN';
        vm.documentsDates = [];
        vm.username = null;
        vm.changeCompany = changeCompany;
        vm.download = download;
        vm.getCurrency = CurrencyUtils.getCurrency;
        $scope.$on('authenticationSuccess', function() {
            init();
        });

        init();

        function download($event, document, fileId, fileType) {
            DocumentDownloadPdf.download($event, document, fileId, fileType);
        }

        function init() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account && vm.account.login) {
                    Account.getHomeInfo().$promise.then(function(res) {
                        vm.homeInfo = res;
                        if (vm.homeInfo.admin) {
                            vm.company = 'all';
                            changeCompany('all');
                        } else {
                            vm.companies = [];
                            var keys = Object.keys(vm.homeInfo);
                            for (var i = 0; i < keys.length; i++) {
                                if (vm.homeInfo[keys[i]].clients) {
                                    vm.companies.push(keys[i]);
                                }
                            }
                            vm.company = 'all';
                            changeCompany('all');
                        }
                    });
                }
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function changeCompany(company) {
            vm.company = company;
            vm.clients = {
                origin: vm.homeInfo[vm.company].clients.ORIGIN,
                destination: vm.homeInfo[vm.company].clients.DESTINATION
            };
            vm.status = {
                origin: vm.homeInfo[vm.company].status.ORIGIN,
                destination: vm.homeInfo[vm.company].status.DESTINATION
            };
            vm.documents = {
                origin: vm.homeInfo[vm.company].documents.ORIGIN,
                destination: vm.homeInfo[vm.company].documents.DESTINATION
            };
            vm.values = {
                origin: vm.homeInfo[vm.company].values.ORIGIN,
                destination: vm.homeInfo[vm.company].values.DESTINATION
            };
            vm.last10Sent = vm.homeInfo[vm.company].last10Sent.ORIGIN;
            vm.last10Received = vm.homeInfo[vm.company].last10Received.DESTINATION;
            vm.valuesSent =
                (vm.values.origin.INVOICE ? vm.values.origin.INVOICE : 0) +
                (vm.values.origin.DEBITNOTE ? vm.values.origin.DEBITNOTE : 0) +
                (vm.values.origin.PROFORMAINVOICE ? vm.values.origin.PROFORMAINVOICE : 0) +
                (vm.values.origin.CONSIGNMENTNOTE ? vm.values.origin.CONSIGNMENTNOTE : 0) -
                (vm.values.origin.CREDITNOTE ? vm.values.origin.CREDITNOTE : 0) -
                (vm.values.origin.SELFBILLED ? vm.values.origin.SELFBILLED : 0);
            vm.valuesReceived =
                (vm.values.destination.INVOICE ? vm.values.destination.INVOICE : 0) +
                (vm.values.destination.DEBITNOTE ? vm.values.destination.DEBITNOTE : 0) +
                (vm.values.destination.PROFORMAINVOICE ? vm.values.destination.PROFORMAINVOICE : 0) +
                (vm.values.destination.CONSIGNMENTNOTE ? vm.values.destination.CONSIGNMENTNOTE : 0) -
                (vm.values.destination.CREDITNOTE ? vm.values.destination.CREDITNOTE : 0) -
                (vm.values.destination.SELFBILLED ? vm.values.destination.SELFBILLED : 0);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .directive('pageRibbon', pageRibbon);

    pageRibbon.$inject = ['ProfileService'];

    function pageRibbon(ProfileService) {
        return {
            replace : true,
            restrict: 'AE',
            template: '<div class="ribbon hidden"><a href="" data-translate="global.ribbon.{{ribbonEnv}}">{{ribbonEnv}}</a></div>',
            link    : linkFunc
        };

        function linkFunc(scope, element) {
            ProfileService.getProfileInfo().then(function(response) {
                if (response.ribbonEnv) {
                    scope.ribbonEnv = response.ribbonEnv;
                    element.addClass(response.ribbonEnv);
                    element.removeClass('hidden');
                }
            });
        }
    }
})();

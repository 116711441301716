(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('AuditsService', AuditsService);

    AuditsService.$inject = ['$resource'];

    function AuditsService ($resource) {
        return $resource('management/audits/:id', {}, {
            'get'  : {
                method : 'GET',
                isArray: true
            },
            'query': {
                method : 'GET',
                isArray: true,
                params : {
                    search: null
                }
            }
        });
    }
})();

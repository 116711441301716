(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('SendGridController', SendGridController);

    SendGridController.$inject = [
        'Notification',
        'SendGridService',
        '$localStorage',
        '$location',
        '$state',
        '$translate',
        '$stateParams',
        '$scope',
        'tmhDynamicLocale'
    ];

    function SendGridController(Notification, SendGridService, $localStorage, $location, $state, $translate, $stateParams, $scope, tmhDynamicLocale) {
        var vm = this;
        vm.email = $stateParams.email;
        vm.errorEmail = false;

        vm.goHome = goHome;
        vm.subscribeAgain = subscribeAgain;

        $scope.$on('$viewContentLoaded', function() {
            var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            vm.errorEmail = false;

            // ADD PATH EMAIL TO LOCAL STORAGE
            if (vm.email && emailPattern.test(vm.email)) {
                $localStorage.email = vm.email;
            } else if ($localStorage.email && emailPattern.test($localStorage.email)) {
                vm.email = $localStorage.email;
            } else {
                vm.errorEmail = true;
                Notification.warning({
                    title: $translate.instant('sendGrid.message.invalidEmail.title'),
                    message: $translate.instant('sendGrid.message.invalidEmail.message'),
                    delay: 5000
                });
            }

            // CHECK USER PREF LANG AND SET TO CURRENT PAGE
            var languageKey = 'pt-pt';
            SendGridService.getLanguageByEmail({ email: vm.email }).$promise.then(
                function(data) {
                    if (data.responseData) {
                        languageKey = data.responseData;
                    }
                    $translate.use(languageKey);
                    tmhDynamicLocale.set(languageKey);
                },
                function() {
                    $translate.use(languageKey);
                    tmhDynamicLocale.set(languageKey);
                }
            );
            // CLEAR EMAIL ADDRESS FROM URL
            $location.search('email', null);
        });

        function subscribeAgain() {
            SendGridService.removeFromList({ email: vm.email }).$promise.then(
                function() {
                    Notification.success({
                        title: $translate.instant('sendGrid.message.subscribed.title'),
                        message: $translate.instant('sendGrid.message.subscribed.message'),
                        delay: 5000
                    });
                    vm.goHome();
                },
                function() {
                    vm.goHome();
                }
            );
        }

        function goHome() {
            // Reset Stored Email
            $localStorage.email = null;
            $state.go('landing-page');
        }
    }
})();

(function () {
    'use strict';

    angular.module('eDocumentsApp').controller('KnowMoreController', KnowMoreController);

    KnowMoreController.$inject = ['$uibModalInstance', '$translate', 'Principal', 'Account', 'Notification'];

    function KnowMoreController($uibModalInstance, $translate, Principal, Account, Notification) {
        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated();

        vm.eDocumentsNewFunctionalities = false;
        vm.flowinnProducts = false;
        vm.beContactedByFlowinn = false;
        vm.email = null;

        vm.isSaving = false;

        vm.save = save;
        vm.clear = clear;

        function save() {
            vm.isSaving = true;
            Account.knowMore({
                eDocumentsNewFunctionalities: vm.eDocumentsNewFunctionalities,
                flowinnProducts: vm.flowinnProducts,
                beContactedByFlowinn: vm.beContactedByFlowinn,
                email: vm.email
            }, function () {
                vm.isSaving = false;
                vm.successTitle = $translate.instant('global.knowMore.success.title');
                vm.successMessage   = $translate.instant('global.knowMore.success.message');
                Notification.success({
                    title: vm.successTitle,
                    message: vm.successMessage
                });
                vm.clear();
            }, function () {
                vm.errorTitle = $translate.instant('global.knowMore.error.title');
                vm.errorMessage   = $translate.instant('global.knowMore.error.message');
                Notification.error({
                    title: vm.errorTitle,
                    message: vm.errorMessage
                });
                vm.isSaving = false;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

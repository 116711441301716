(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('DocumentApprovalController', DocumentApprovalController);

    DocumentApprovalController.$inject = ['$uibModalInstance', 'entity', 'Document', 'CurrencyUtils'];

    function DocumentApprovalController($uibModalInstance, entity, Document, CurrencyUtils) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.confirmApproval = confirmApproval;
        vm.getCurrency = CurrencyUtils.getCurrency;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmApproval(id) {
            Document.approve({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('eDocumentsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('main-index', {
                abstract: true,
                views   : {
                    'main@': {
                        templateUrl: 'app/layouts/main-index.html'
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html',
                        controller: 'FooterController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('main', {
                abstract: true,
                views   : {
                    'main@': {
                        templateUrl: 'app/layouts/main.html'
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html',
                        controller: 'FooterController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('app', {
                parent  : 'main',
                abstract: true,
                views   : {
                    'main@': {
                        templateUrl: 'app/layouts/main.html'
                    },
                    'navbar@' : {
                        templateUrl : 'app/layouts/navbar/navbar.html',
                        controller  : 'NavbarController',
                        controllerAs: 'vm'
                    },
                    'sidebar@': {
                        templateUrl : 'app/layouts/sidebar/sidebar.html',
                        controller  : 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.html',
                        controller: 'FooterController',
                        controllerAs: 'vm'
                    }
                },
                resolve : {
                    authorize             : ['Auth', function (Auth) {
                        return Auth.authorize();
                    }],
                    translatePartialLoader: ['$translatePartialLoader', function ($translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                    }]
                }
            });
    }
})();

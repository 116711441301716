/* global d3:true, _:true */

(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('LandingPageController', LandingPageController);

    LandingPageController.$inject = [
        '$scope',
        'Account',
        'Principal',
        '$state',
        'Auth',
        '$rootScope',
        '$translate',
        'PaginationUtil',
        'Notification',
        'ContactUsService',
        'JhiLanguageService',
        'DocumentationUtils'
    ];

    function LandingPageController(
        $scope,
        Account,
        Principal,
        $state,
        Auth,
        $rootScope,
        $translate,
        PaginationUtil,
        Notification,
        ContactUsService,
        DocumentationUtils
    ) {
        var vm = this;
        vm.authenticationError = false;
        vm.credentials = {};
        vm.password = null;
        vm.rememberMe = true;
        vm.email = null;
        vm.isSaving = false;
        vm.addToNewsletter = addToNewsletter;
        vm.login = login;
        vm.downloadManual = downloadManual;
        vm.contactUs = contactUs;
        $scope.$on('authenticationSuccess', function() {
            init();
        });

        function addToNewsletter() {
            vm.isSaving = true;
            Account.addToNewsletter(
                {
                    email: vm.email
                },
                function() {
                    Notification.success({
                        message: $translate.instant('global.addToNewsletter.success.message'),
                        delay: 5000
                    });
                    vm.email = '';
                    vm.isSaving = false;
                },
                function() {
                    Notification.error({
                        message: $translate.instant('global.addToNewsletter.error.message'),
                        delay: 5000
                    });
                    vm.isSaving = false;
                }
            );
        }

        function login(event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            })
                .then(function() {
                    PaginationUtil.resetFilters();
                    vm.authenticationError = false;
                    $rootScope.$broadcast('authenticationSuccess');
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    } else {
                        $state.go('document');
                    }
                })
                .catch(function() {
                    vm.authenticationError = true;
                });
        }

        init();

        function init() {
            Principal.identity().then(function(account) {
                if (account && account.login) {
                    $state.go('document');
                }
            });
        }

        function downloadManual() {
            DocumentationUtils.downloadUserManual();
        }

        function contactUs() {
            ContactUsService.open();
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('eDocumentsApp')
        .factory('DocumentSearchByUser', DocumentSearchByUser);

    DocumentSearchByUser.$inject = ['$resource'];

    function DocumentSearchByUser($resource) {
        var resourceUrl = 'api/document-search-by-user/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'query': { method: 'GET', isArray: true },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            }
        });
    }
})();

/* global Uint8Array:true, moment:true, momentBusiness:true */
(function() {
    'use strict';

    angular.module('eDocumentsApp').service('MyUtil', MyUtil);

    MyUtil.$inject = [];

    function MyUtil() {

        this.isEmpty = function(data) {
            return (angular.isUndefined(data) || data == null || data.length === 0);
        };

    }
})();

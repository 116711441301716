(function() {
    'use strict';

    var jhiAlertError = {
        template: '<div class="alerts" ng-cloak="" role="alert">' + '<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' + '<div uib-alert ng-cloak="" class="alert alert-{{alert.type}}" close="alert.close($ctrl.alerts)"><pre>{{ alert.msg }}</pre></div>' + '</div>' + '</div>',
        controller: jhiAlertErrorController
    };

    angular
        .module('eDocumentsApp')
        .component('jhiAlertError', jhiAlertError);

    jhiAlertErrorController.$inject = ['$scope', '$rootScope', '$translate', 'Notification'];

    function jhiAlertErrorController($scope, $rootScope, $translate, Notification) {
        var vm = this;

        vm.alerts = [];

        function addErrorAlert(message, key, data) {
            key = key ? key : message;
            var msg = $translate.instant(key, data);
            if (key.data && key.data.title) {
                msg = $translate.instant(key.data.title, data);
            }
            Notification.error({
                message: msg
            });
            // vm.alerts.push(
            //     AlertService.add(
            //         {
            //             type: 'danger',
            //             msg: key,
            //             params: data,
            //             timeout: 5000,
            //             toast: AlertService.isToast(),
            //             scoped: true
            //         },
            //         vm.alerts
            //     )
            // );
        }

        var cleanHttpErrorListener = $rootScope.$on('eDocumentsApp.httpError', function(event, httpResponse) {
            var i;
            event.stopPropagation();
            switch (httpResponse.status) {
                // connection refused, server not reachable
            case 0:
                addErrorAlert('Server not reachable', 'error.server.not.reachable');
                break;

            case 400:
                var headers = Object.keys(httpResponse.headers()).filter(function(header) {
                    return header.indexOf('app-error', header.length - 'app-error'.length) !== -1 || header.indexOf('app-params', header.length - 'app-params'.length) !== -1;
                }).sort();
                var errorHeader = httpResponse.headers(headers[0]);
                var entityKey = httpResponse.headers(headers[1]);
                if (angular.isString(errorHeader)) {
                    var entityName = $translate.instant('global.menu.entities.' + entityKey);
                    addErrorAlert(errorHeader, errorHeader, { entityName: entityName });
                } else if (httpResponse.data && httpResponse.data.fieldErrors) {
                    for (i = 0; i < httpResponse.data.fieldErrors.length; i++) {
                        var fieldError = httpResponse.data.fieldErrors[i];
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                        var convertedField = fieldError.field.replace(/\[\d*]/g, '[]');
                        var fieldName = $translate.instant('eDocumentsApp.' + fieldError.objectName + '.' + convertedField);
                        addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, { fieldName: fieldName });
                    }
                } else if (httpResponse.data && httpResponse.data.message) {
                    addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
                } else {
                    addErrorAlert(httpResponse.data);
                }
                break;

            case 404:
                addErrorAlert('Not found', 'error.url.not.found');
                break;

            default:
                if (httpResponse.data && httpResponse.data.message) {
                    addErrorAlert(httpResponse.data.message);
                } else {
                    var headers1 = Object.keys(httpResponse.headers()).filter(function(header) {
                        return header.indexOf('app-alert', header.length - 'app-alert'.length) !== -1 || header.indexOf('app-params', header.length - 'app-params'.length) !== -1;
                    }).sort();
                    var alertKey = httpResponse.headers(headers1[0]);
                    if (angular.isString(alertKey)) {
                        var msg = $translate.instant(alertKey, httpResponse.headers(headers1[1]));
                        Notification.error({
                            message: msg
                        });
                            // AlertService.error(alertKey, {param: });
                    } else {
                        addErrorAlert(httpResponse);
                    }
                }
            }
        });

        $scope.$on('$destroy', function() {
            if (angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null) {
                cleanHttpErrorListener();
                vm.alerts = [];
            }
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('eDocumentsApp')
        .factory('DocumentStorage', DocumentStorage);

    DocumentStorage.$inject = ['$resource'];

    function DocumentStorage($resource) {
        var resourceUrl = 'api/document-storages/:id';

        return $resource(resourceUrl, {}, {
            'query'        : {
                method : 'GET',
                isArray: true
            },
            'get'          : {
                method           : 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getByDocument': {
                url:'api/document-storages-by-document/:id',
                method           : 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update'       : {method: 'PUT'}
        });
    }
})();

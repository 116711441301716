(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window'];

    function DataUtils($window) {

        var JSON_TYPE = 'JSON';
        var STRING_TYPE = 'STRING';
        var XML_TYPE = 'XML';

        return {
            abbreviate: abbreviate,
            viewServerFile: viewServerFile,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            parseHeaderFilename: parseHeaderFilename,
            getInputType: getInputType,
            getErrorMessageFromArrayBuffer: getErrorMessageFromArrayBuffer,
            getViewableFilename: getViewableFilename,
            JSON_TYPE: JSON_TYPE,
            STRING_TYPE: STRING_TYPE,
            XML_TYPE: XML_TYPE
        };

        function viewServerFile(result) {
            var url = URL.createObjectURL(new Blob([result.data]));
            var link = document.createElement('a');
            link.href = url;
            link.style = 'visibility:hidden';
            if (result.filename.length > 6 && result.filename.substring(5, 6) === '_') {
                result.filename = result.filename.substring(6);
            }
            link.download = result.filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function parseHeaderFilename(headers) {
            var header = headers('content-disposition');
            if (!header) {
                return null;
            }
            if (header === null) {
                return null;
            }
            var result = header.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile(type, data) {
            var fileURL = 'data:' + type + ';base64,' + data;
            var win = $window.open('', '_blank');
            win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>');
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                var base64Data = e.target.result.substring(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function getInputType(input) {
            try {
                //try to parse via json
                angular.fromJson(input);
                return JSON_TYPE;
            } catch (e) {
                try {
                    $.parseXML(input); //is valid XML
                    return XML_TYPE;
                } catch (err) {
                    // was not XML
                    return STRING_TYPE;
                }
            }
        }

        function getErrorMessageFromArrayBuffer(data) {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));
            var obj = JSON.parse(decodedString);
            return obj['message'];
        }

        function getViewableFilename(filename) {
            if (filename.length > 6 && filename.substring(5, 6) === '_') {
                return filename.substring(6);
            }
            return filename;
        }
    }
})();

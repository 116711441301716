(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('ViewParametersDetailsDialogController', ViewParametersDetailsDialogController);

    ViewParametersDetailsDialogController.$inject = ['$uibModalInstance', 'communicationDefinition', 'communicationEntityDestination', 'communicationEntityOrigin', 'CommunicationEntity'];

    function ViewParametersDetailsDialogController($uibModalInstance, communicationDefinition, communicationEntityDestination, communicationEntityOrigin, CommunicationEntity) {
        var vm = this;

        vm.communicationDefinition = communicationDefinition;
        if (vm.communicationDefinition) {
            CommunicationEntity.get({ id: vm.communicationDefinition.originId }, function(result) {
                vm.communicationEntityOrigin = result;
            });
            CommunicationEntity.get({ id: vm.communicationDefinition.destinationId }, function(result) {
                vm.communicationEntityDestination = result;
            });
        } else {
            vm.communicationEntityOrigin = communicationEntityOrigin;
            vm.communicationEntityDestination = communicationEntityDestination;
        }
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('JhiLanguageController', JhiLanguageController);

    JhiLanguageController.$inject = ['$translate', 'JhiLanguageService', 'tmhDynamicLocale'];

    function JhiLanguageController($translate, JhiLanguageService, tmhDynamicLocale) {
        var vm = this;

        vm.changeLanguage = changeLanguage;
        vm.findLanguageIcon = findLanguageIcon;
        vm.getCurrentLanguageIcon = getCurrentLanguageIcon;
        vm.getCurrentLanguage = getCurrentLanguage;
        vm.languages = null;
        JhiLanguageService.getAll().then(function(languages) {
            vm.languages = languages;
        });

        function getCurrentLanguage() {
            return JhiLanguageService.getCurrent();
        }

        // translateService.currentLang
        function changeLanguage(languageKey) {
            $translate.use(languageKey);
            tmhDynamicLocale.set(languageKey);
        }

        function getCurrentLanguageIcon() {
            return JhiLanguageService.getCurrentLanguageIcon();
        }

        function findLanguageIcon(languageKey) {
            return JhiLanguageService.findLanguageIcon(languageKey);
        }
    }
})();

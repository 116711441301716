(function() {
    'use strict';
    angular
        .module('eDocumentsApp')
        .factory('CommunicationEntity', CommunicationEntity);

    CommunicationEntity.$inject = ['$resource', 'DateUtils'];

    function CommunicationEntity($resource, DateUtils) {
        var resourceUrl = 'api/communication-entities/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET', isArray: true
            }, 'queryAll': {
                url: 'api/communication-entities-all/', method: 'GET', isArray: true
            }, 'queryForSelect': {
                url: 'api/communication-entities-for-select/', method: 'GET', isArray: true
            }, 'findAllByUser': {
                url: 'api/communication-entities-by-user/', method: 'GET', isArray: true
            }, 'searchByName': {
                url: 'api/communication-entities-by-name/', method: 'GET', isArray: true
            }, 'findByGln': {
                url: 'api/communication-entities/gln/', method: 'GET', isArray: false
            }, 'get': {
                method: 'GET', transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.originParameters) {
                            for (var i = 0; i < data.originParameters.length; i++) {
                                if (data.originParameters[i].communicationParameterType === 'EXECUTION_TIME' || data.originParameters[i].communicationParameterType === 'EMAIL_EXECUTION_TIME' || data.originParameters[i].communicationParameterType === 'SUSPENSION_START_TIME' || data.originParameters[i].communicationParameterType === 'SUSPENSION_END_TIME') {
                                    if (data.originParameters[i].value !== -1 && data.originParameters[i].value !== '-1') {
                                        data.originParameters[i].value = DateUtils.convertStringToTime(data.originParameters[i].value);
                                    } else {
                                        data.originParameters[i].value = undefined;
                                        data.originParameters[i].disableExecutionTime = true;
                                    }
                                }
                            }
                        }
                        if (data.destinationParameters) {
                            for (var j = 0; j < data.destinationParameters.length; j++) {
                                if (data.destinationParameters[j].communicationParameterType === 'EXECUTION_TIME' || data.destinationParameters[j].communicationParameterType === 'EMAIL_EXECUTION_TIME' || data.destinationParameters[j].communicationParameterType === 'SUSPENSION_START_TIME' || data.destinationParameters[j].communicationParameterType === 'SUSPENSION_END_TIME') {
                                    if (data.destinationParameters[j].value !== -1 && data.destinationParameters[j].value !== '-1') {
                                        data.destinationParameters[j].value = DateUtils.convertStringToTime(data.destinationParameters[j].value);
                                    } else {
                                        data.destinationParameters[j].value = undefined;
                                        data.destinationParameters[j].disableExecutionTime = true;
                                    }
                                }
                            }
                        }
                    }
                    return data;
                }
            }, 'update': {
                method: 'PUT', transformRequest: function(data) {
                    if (data && data.originParameters) {
                        for (var i = 0; i < data.originParameters.length; i++) {
                            if (data.originParameters[i].communicationParameterType === 'EXECUTION_TIME' || data.originParameters[i].communicationParameterType === 'EMAIL_EXECUTION_TIME' || data.originParameters[i].communicationParameterType === 'SUSPENSION_START_TIME' || data.originParameters[i].communicationParameterType === 'SUSPENSION_END_TIME') {
                                if (data.originParameters[i].value !== -1) {
                                    data.originParameters[i].value = DateUtils.convertTimeToString(data.originParameters[i].value);
                                }
                            }
                        }
                    }
                    if (data && data.destinationParameters) {
                        for (var j = 0; j < data.destinationParameters.length; j++) {
                            if (data.destinationParameters[j].communicationParameterType === 'EXECUTION_TIME' || data.destinationParameters[j].communicationParameterType === 'EMAIL_EXECUTION_TIME' || data.destinationParameters[j].communicationParameterType === 'SUSPENSION_START_TIME' || data.destinationParameters[j].communicationParameterType === 'SUSPENSION_END_TIME') {
                                if (data.destinationParameters[j].value !== -1) {
                                    data.destinationParameters[j].value = DateUtils.convertTimeToString(data.destinationParameters[j].value);
                                }
                            }
                        }
                    }
                    return angular.toJson(data);
                }
            }, 'omniboxSearch': {
                method: 'GET', isArray: true, url: 'api/communication-entities/omniboxSearch'
            }
        });
    }
})();

/* global moment:true, _:true*/
(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('SmartSearchController', SmartSearchController);

    SmartSearchController.$inject = [
        '$state',
        '$scope',
        'ParseLinks',
        'AlertService',
        'paginationConstants',
        'PaginationUtil',
        '$window',
        'InvoiceHeader',
        'CommunicationEntity',
        'InvoiceLine',
        'CurrencyUtils'
    ];

    function SmartSearchController(
        $state,
        $scope,
        ParseLinks,
        AlertService,
        paginationConstants,
        PaginationUtil,
        $window,
        InvoiceHeader,
        CommunicationEntity,
        InvoiceLine,
        CurrencyUtils
    ) {
        var vm = this;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.reset = reset;
        vm.loadAll = loadAll;
        vm.loadInvoiceHeaders = loadInvoiceHeaders;
        vm.loadInvoiceLines = loadInvoiceLines;
        vm.loadCommunicationEntities = loadCommunicationEntities;
        vm.initFilters = initFilters;
        vm.totalItems = 1000;
        vm.isTableLoading = true;
        vm.getCurrency = CurrencyUtils.getCurrency;
        vm.types = ['INVOICE', 'CREDITNOTE', 'DEBITNOTE', 'SELFBILLED', 'PROFORMAINVOICE', 'CONSIGNMENTNOTE', 'ORDER'];
        vm.states = ['IN_COMMUNICATION', 'PROCESSING', 'WAITING', 'SCHEDULED', 'FINISHED', 'ERROR', 'DUPLICATED'];

        vm.filters = {};
        vm.page = {};
        vm.predicate = {};
        vm.reverse = {};
        vm.totalItems = {};
        vm.queryCount = {};
        vm.pagination = {};
        vm.pagination.invoiceHeader = {};
        vm.pagination.invoiceLine = {};
        vm.pagination.communicationEntity = {};

        vm.isCollapseInvoiceHeadersOpen = false;
        vm.isCollapseInvoiceLinesOpen = false;
        vm.isCollapsedCommunicationEntitiesOpen = false;

        function isSmallScreen() {
            var w = angular.element($window);
            vm.width = w.width();
            $scope.$apply();
        }

        $(window).resize(function() {
            isSmallScreen();
        });

        function reset() {
            vm.filters.invoiceHeader = PaginationUtil.clearFilters($state.current.name + '_invoiceHeader', filterBy());
            vm.filters.invoiceLine = PaginationUtil.clearFilters($state.current.name + '_invoiceLine', filterBy());
            vm.filters.communicationEntity = PaginationUtil.clearFilters($state.current.name + '_communicationEntity', filterBy());
            vm.omniboxSearchValue = null;
            loadAll();
        }

        function filterBy() {
            return {
                omniboxSearch: {
                    name: 'omniboxSearch',
                    value: undefined
                }
            };
        }

        function initFilters() {
            vm.filters.invoiceHeader = PaginationUtil.getFilters($state.current.name + '_invoiceHeader', filterBy());
            vm.filters.invoiceLine = PaginationUtil.getFilters($state.current.name + '_invoiceLine', filterBy());
            vm.filters.communicationEntity = PaginationUtil.getFilters($state.current.name + '_communicationEntity', filterBy());
            vm.omniboxSearchValue = vm.filters.invoiceHeader.filterBy.omniboxSearch.value;

            vm.pagination.invoiceHeader.page = vm.filters.invoiceHeader.currentPage + 1;
            vm.pagination.invoiceLine.page = vm.filters.invoiceLine.currentPage + 1;
            vm.pagination.communicationEntity.page = vm.filters.communicationEntity.currentPage + 1;
            vm.predicate.invoiceHeader = vm.filters.invoiceHeader.orderBy;
            vm.predicate.invoiceLine = vm.filters.invoiceLine.orderBy;
            vm.predicate.communicationEntity = vm.filters.communicationEntity.orderBy;
            vm.reverse.invoiceHeader = vm.filters.invoiceHeader.orderReverse;
            vm.reverse.invoiceLine = vm.filters.invoiceLine.orderReverse;
            vm.reverse.communicationEntity = vm.filters.communicationEntity.orderReverse;
            loadAll();
        }

        vm.$onInit = function() {
            initFilters();
        };

        function loadInvoiceHeaders() {
            vm.filters.invoiceHeader = PaginationUtil.updateFilters(
                $state.current.name + '_invoiceHeader',
                vm.pagination.invoiceHeader.page - 1,
                vm.filters.invoiceHeader.filterBy,
                vm.predicate.invoiceHeader,
                vm.reverse.invoiceHeader,
                vm.itemsPerPage
            );
            if (vm.filters.invoiceHeader.filterBy.omniboxSearch.value) {
                InvoiceHeader.omniboxSearch(
                    {
                        omniboxSearchValue: vm.filters.invoiceHeader.filterBy.omniboxSearch.value,
                        page: vm.filters.invoiceHeader.currentPage,
                        size: vm.filters.invoiceHeader.itemsPerPage,
                        sort: sort()
                    },
                    onSuccess,
                    onError
                );
            } else {
                vm.invoiceHeaders = {};
            }

            function sort() {
                if (!vm.predicate.invoiceHeader) {
                    vm.predicate.invoiceHeader = 'id';
                }
                var result = [vm.predicate.invoiceHeader + ',' + (vm.reverse.invoiceHeader ? 'asc' : 'desc')];
                if (vm.predicate.invoiceHeader !== 'id') {
                    result.push('id,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pagination.invoiceHeader.totalItems = headers('X-Total-Count');
                vm.pagination.invoiceHeader.queryCount = vm.pagination.invoiceHeader.totalItems;
                vm.invoiceHeaders = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadInvoiceLines() {
            vm.filters.invoiceLine = PaginationUtil.updateFilters(
                $state.current.name + '_invoiceLine',
                vm.pagination.invoiceLine.page - 1,
                vm.filters.invoiceLine.filterBy,
                vm.predicate.invoiceLine,
                vm.reverse.invoiceLine,
                vm.itemsPerPage
            );
            if (vm.filters.invoiceLine.filterBy.omniboxSearch.value) {
                InvoiceLine.omniboxSearch(
                    {
                        omniboxSearchValue: vm.filters.invoiceLine.filterBy.omniboxSearch.value,
                        page: vm.filters.invoiceLine.currentPage,
                        size: vm.filters.invoiceLine.itemsPerPage,
                        sort: sort()
                    },
                    onSuccess,
                    onError
                );
            } else {
                vm.invoiceLines = {};
            }

            function sort() {
                if (!vm.predicate.invoiceLine) {
                    vm.predicate.invoiceLine = 'id';
                }
                var result = [vm.predicate.invoiceLine + ',' + (vm.reverse.invoiceLine ? 'asc' : 'desc')];
                if (vm.predicate.invoiceLine !== 'id') {
                    result.push('id,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pagination.invoiceLine.totalItems = headers('X-Total-Count');
                vm.pagination.invoiceLine.queryCount = vm.pagination.invoiceLine.totalItems;
                vm.invoiceLines = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadCommunicationEntities() {
            vm.filters.communicationEntity = PaginationUtil.updateFilters(
                $state.current.name + '_communicationEntity',
                vm.pagination.communicationEntity.page - 1,
                vm.filters.communicationEntity.filterBy,
                vm.predicate.communicationEntity,
                vm.reverse.communicationEntity,
                vm.itemsPerPage
            );
            if (vm.filters.communicationEntity.filterBy.omniboxSearch.value) {
                CommunicationEntity.omniboxSearch(
                    {
                        omniboxSearchValue: vm.filters.communicationEntity.filterBy.omniboxSearch.value,
                        page: vm.filters.communicationEntity.currentPage,
                        size: vm.filters.communicationEntity.itemsPerPage,
                        sort: sort()
                    },
                    onSuccess,
                    onError
                );
            } else {
                vm.communicationEntities = {};
            }

            function sort() {
                if (!vm.predicate.communicationEntity) {
                    vm.predicate.communicationEntity = 'id';
                }
                var result = [vm.predicate.communicationEntity + ',' + (vm.reverse.communicationEntity ? 'asc' : 'desc')];
                if (vm.predicate.communicationEntity !== 'id') {
                    result.push('id,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.pagination.communicationEntity.totalItems = headers('X-Total-Count');
                vm.pagination.communicationEntity.queryCount = vm.pagination.communicationEntity.totalItems;
                vm.communicationEntities = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAll() {
            vm.filters.invoiceHeader.filterBy.omniboxSearch.value = vm.omniboxSearchValue;
            vm.filters.invoiceLine.filterBy.omniboxSearch.value = vm.omniboxSearchValue;
            vm.filters.communicationEntity.filterBy.omniboxSearch.value = vm.omniboxSearchValue;
            vm.filters.invoiceHeader = PaginationUtil.updateFilters(
                $state.current.name + '_invoiceHeader',
                vm.pagination.invoiceHeader.page - 1,
                vm.filters.invoiceHeader.filterBy,
                vm.predicate.invoiceHeader,
                vm.reverse.invoiceHeader,
                vm.itemsPerPage
            );
            vm.filters.invoiceLine = PaginationUtil.updateFilters(
                $state.current.name + '_invoiceLine',
                vm.pagination.invoiceLine.page - 1,
                vm.filters.invoiceLine.filterBy,
                vm.predicate.invoiceLine,
                vm.reverse.invoiceLine,
                vm.itemsPerPage
            );
            vm.filters.communicationEntity = PaginationUtil.updateFilters(
                $state.current.name + '_communicationEntity',
                vm.pagination.communicationEntity.page - 1,
                vm.filters.communicationEntity.filterBy,
                vm.predicate.communicationEntity,
                vm.reverse.communicationEntity,
                vm.itemsPerPage
            );
            vm.emptySearchBox = !vm.filters.invoiceHeader.filterBy.omniboxSearch.value;
            vm.loadInvoiceHeaders();
            vm.loadInvoiceLines();
            vm.loadCommunicationEntities();
        }
    }
})();

(function() {
    'use strict';

    angular.module('eDocumentsApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('faqs', {
            parent: 'app',
            url: '/faqs',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.faqs'
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/faqs/faqs.html',
                    controller: 'FAQsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('landingPage');
                        $translatePartialLoader.addPart('faqs');
                        return $translate.refresh();
                    }
                ]
            }
        }).state('faqsExternalAccess', {
            parent: 'external',
            url: '/external/faqs',
            data: {
                authorities: [],
                pageTitle: 'global.faqs'
            },
            onEnter: ['$state', 'Principal', function($state, Principal) {
                if (Principal.isAuthenticated()) {
                    $state.go('faqs');
                } else {
                    $state.go('landing-page', { section: 'faqs' });
                }
            }]
        });
    }
})();

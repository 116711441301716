(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('CommunicationAuthoritiesDialogController', CommunicationAuthoritiesDialogController);

    CommunicationAuthoritiesDialogController.$inject = ['$scope', '$uibModalInstance', 'user', 'entity', 'readOnly', 'CommunicationDefinitionAuthority', 'CommunicationDefinitionSearch', 'Notification', 'User'];

    function CommunicationAuthoritiesDialogController($scope, $uibModalInstance, user, entity, readOnly, CommunicationDefinitionAuthority, CommunicationDefinitionSearch, Notification, User) {
        var vm = this;

        vm.readOnly = readOnly;

        vm.entity = entity;
        vm.user = user;

        vm.communicationDefinitionAuthority = {
            userEntity: {
                entityId: vm.entity,
                userId: vm.user.id
            },
            communicationDefinitionId: null
        };

        vm.clear = clear;
        vm.save = save;
        vm.userentities = [];
        vm.communicationdefinitions = [];

        vm.loadAll = loadAll;
        vm.deleteCommunicationDefinitionAuthority = deleteCommunicationDefinitionAuthority;
        vm.toggleEmitter = toggleEmitter;
        vm.toggleReceptor = toggleReceptor;

        loadAll();

        function loadAll() {
            CommunicationDefinitionAuthority.queryByUserAndEntity({
                userId: vm.user.id,
                entityId: vm.entity
            }, function(result) {
                vm.communicationDefinitionAuthorities = result;
            });
        }

        $scope.refreshSelectModel = function(query) {
            if (vm.lastQuery !== query) {
                vm.lastQuery = query;

                var searchObj = buildSearch(query);

                CommunicationDefinitionSearch.searchList({ search: searchObj }, function(result) {
                    if (result.length > 50) {
                        Notification.warning({
                            message: 'O número de resultados é superior a 50 registos. Por questões de performance, refine a sua pesquisa',
                            delay: 5000
                        });
                    } else {
                        vm.communicationdefinitions = result;
                    }
                });
            }
        };

        function buildSearch(query) {
            var result = '';

            result += 'origin.id' + ':' + vm.entity + '#OR,';
            result += 'destination.id' + ':' + vm.entity + '#AND,';
            result += 'description' + ':' + query + '#AND,';

            return result;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            CommunicationDefinitionAuthority.save(vm.communicationDefinitionAuthority, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('eDocumentsApp:communicationDefinitionAuthorityUpdate', result);
            loadAll();
            Notification.success({ message: 'Registo adicionado com sucesso', delay: 5000 });
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function deleteCommunicationDefinitionAuthority(id) {
            CommunicationDefinitionAuthority.delete({ id: id },
                function() {
                    loadAll();
                    Notification.success({ message: 'Registo apagado com sucesso', delay: 5000 });
                });
        }

        function toggleEmitter(emitter) {
            User.updateUserEntity({
                userId: vm.user.id,
                entityId: vm.entity,
                manager: vm.user.manager,
                emitter: !emitter,
                receptor: vm.user.receptor
            }, function() {
                Notification.success({ message: 'Utilizador atualizado!' });
                vm.user.emitter = !emitter;
            }, function() {
                Notification.error({ message: 'Ocorreu um erro ao atualizar o utilizador!' });
            });
        }

        function toggleReceptor(receptor) {
            User.updateUserEntity({
                userId: vm.user.id,
                entityId: vm.entity,
                manager: vm.user.manager,
                emitter: vm.user.emitter,
                receptor: !receptor
            }, function() {
                Notification.success({ message: 'Utilizador atualizado!' });
                vm.user.receptor = !receptor;
            }, function() {
                Notification.error({ message: 'Ocorreu um erro ao atualizar o utilizador!' });
            });
        }

    }
})();

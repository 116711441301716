(function() {
    'use strict';

    angular.module('eDocumentsApp').controller('SendGridCheckerController', SendGridCheckerController);

    SendGridCheckerController.$inject = ['Notification', 'SendGridService', '$state', '$translate'];

    function SendGridCheckerController(Notification, SendGridService, $state, $translate) {
        var vm = this;
        vm.step1 = true;
        vm.step2 = false;
        vm.step3 = false;
        vm.email = null;

        vm.getEmailStatus = getEmailStatus;
        vm.removeFromList = removeFromList;
        vm.back = back;

        function getEmailStatus() {
            if (vm.email) {
                SendGridService.getStatus({ email: vm.email }).$promise.then(
                    function(resp) {
                        if (resp.recipient_email) {
                            vm.step1 = false;
                            vm.step2 = true;
                            vm.step3 = false;
                        } else {
                            vm.step1 = false;
                            vm.step2 = false;
                            vm.step3 = true;
                        }
                    },
                    function() {
                        errorMessage($translate.instant('sendGrid.message.error.serverError'));
                    }
                );
            } else {
                errorMessage($translate.instant('sendGrid.message.error.emailError'));
            }
        }

        function removeFromList() {
            if (vm.email) {
                SendGridService.removeFromList({ email: vm.email }).$promise.then(
                    function() {
                        vm.step1 = true;
                        vm.step2 = false;
                        vm.step3 = false;
                        vm.email = null;
                        successMessage($translate.instant('sendGrid.message.success.emailRemoved'));
                    },
                    function() {
                        errorMessage($translate.instant('sendGrid.message.error.serverError'));
                    }
                );
            } else {
                errorMessage($translate.instant('sendGrid.message.error.emailError'));
            }
        }

        function back() {
            vm.step1 = true;
            vm.step2 = false;
            vm.step3 = false;
            vm.email = null;
            $state.go('send-grid-checker');
        }

        function errorMessage(message) {
            Notification.error({
                message: message,
                delay: 5000
            });
        }

        function successMessage(message) {
            Notification.success({
                message: message,
                delay: 5000
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('eDocumentsApp')
        .factory('Communication', Communication);

    Communication.$inject = ['$resource', 'DateUtils'];

    function Communication($resource, DateUtils) {
        var resourceUrl = 'api/communications/:id';

        return $resource(resourceUrl, {}, {
            'query'             : {
                method : 'GET',
                isArray: true
            },
            'findByWorkflowStep': {
                method : 'GET',
                url    : 'api/communication-by-workflow-step/:id',
                isArray: true
            },
            'findByDefinition'  : {
                method : 'GET',
                url    : 'api/communication-by-definition/:id',
                isArray: true
            },
            'findByDocument'  : {
                method : 'GET',
                url    : 'api/communication-by-document/:id',
                isArray: true
            },
            'get'               : {
                method           : 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data                   = angular.fromJson(data);
                        data.communicationTime = DateUtils.convertDateTimeFromServer(data.communicationTime);
                    }
                    return data;
                }
            },
            'update'            : {method: 'PUT'}
        });
    }
})();

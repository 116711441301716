(function () {
    'use strict';

    var jhiAlert = {
        // template: '<div class="alerts" ng-cloak="" role="alert">' +
        //                 '<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
        //                     '<div uib-alert ng-cloak="" class="alert alert-{{alert.type}}" close="alert.close($ctrl.alerts)"><pre ng-bind-html="alert.msg"></pre></div>' +
        //                 '</div>' +
        //           '</div>',
        controller: jhiAlertController
    };

    angular
        .module('eDocumentsApp')
        .component('jhiAlert', jhiAlert);

    jhiAlertController.$inject = ['$scope', 'AlertService', 'Notification'];

    function jhiAlertController($scope, AlertService, Notification) {
        var vm = this;

        vm.alerts = AlertService.get();
        for (var i in vm.alerts) {
            switch (vm.alerts[i].type) {
            case 'success':
                Notification.success({
                    message: vm.alerts[i].msg,
                    delay  : 3000
                });
                break;
            case 'danger':
                Notification.error({
                    message: vm.alerts[i].msg,
                    delay  : 3000
                });
                break;
            case 'warning':
                Notification.warning({
                    message: vm.alerts[i].msg,
                    delay  : 3000
                });
                break;
            case 'info':
                Notification.info({
                    message: vm.alerts[i].msg,
                    delay  : 3000
                });
                break;
            default           :
                Notification.primary    ({
                    message: vm.alerts[i].msg,
                    delay  : 3000
                });
                break;
            }

        }
        AlertService.clear();
        $scope.$on('$destroy', function () {
            vm.alerts = [];
        });

    }
})();

(function() {
    'use strict';

    angular
        .module('eDocumentsApp')
        .controller('UnitOfMeasureDialogController', UnitOfMeasureDialogController);

    UnitOfMeasureDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'UnitOfMeasure'];

    function UnitOfMeasureDialogController ($timeout, $scope, $uibModalInstance, entity, UnitOfMeasure) {
        var vm = this;

        vm.unitOfMeasure = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.unitOfMeasure.id !== null) {
                UnitOfMeasure.update(vm.unitOfMeasure, onSaveSuccess, onSaveError);
            } else {
                UnitOfMeasure.save(vm.unitOfMeasure, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eDocumentsApp:unitOfMeasureUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
